import moment from 'moment';
import {
  updateDayRequest,
  setDayTypeRequest,
  getAttendancesRequest,
  getWorkShedulesRequest,
  downloadReportRequest,
  removeAttendanceRequest,
  createAttendanceRequest,
  getTypesOfDayRequest,
  getLocations,
  getAttendances,
  updateAttendance,
  deleteAttendance,
  getMyAttendances,
  editPeriodRequest
} from '@/api/attendances';
import { downloadFile } from '@/utils/files';
import {showSuccessToast} from "../../utils/toasts";
import { VACATION_HOURS_TYPE } from '@/utils/constants';

export const state = {
  calendar: [],
  employees: [],
  items: [],
  months: [],
  loading: false,
  data: {},
  timelines: {
    employees: []
  },
  showCompensation: false,
  vacationHoursType: VACATION_HOURS_TYPE.SPENT,
  filters: null,
  dictionary: {
    day_types: [],
    locations: []
  },
  attendances: [],
  holidays: [],
  selectedDay: null,
  profileAttendances: []
}

export const mutations = {
  SET_CALENDAR(state, list) {
    state.calendar = list
  },
  SET_EMPLOYEES(state, list) {
    state.employees = list;
  },
  SET_ATTENDANCES (state, list) {
    state.attendances = list
  },
  SET_HOLIDAYS (state, list) {
    state.holidays = list
  },
  SET_ITEMS(state, list) {
    state.items = list;
  },
  SET_MONTHS(state, list) {
    state.months = list;
  },
  SET_LOADING(state, value) {
    state.loading = value;
  },
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_TIMELINES(state, data) {
    state.timelines = data;
  },
  SET_SHOW_COMPENSATION(state, data) {
    state.showCompensation = data;
  },
  SET_FILTERS(state, data){
    state.filters = data
  },
  SET_DAY_TYPES (state, { name, data }) {
    state.dictionary[name] = data
  },
  SET_SELECTED_DAY (state, data) {
    state.selectedDay = data
  },
  SET_PROFILE_ATTENDANCES (state, data) {
    state.profileAttendances = data
  },
  SET_VACATION_HOURS_TYPE(state, value) {
    state.vacationHoursType = value;
  }
}

export const actions = {
  async getAttendances({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getAttendancesRequest(payload);
      commit('SET_DATA', data);
      commit('SET_CALENDAR', data.calendar);
      commit('SET_EMPLOYEES', data.employees);
      commit('SET_ITEMS', data.items);
      commit('SET_MONTHS', data.months);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async setDayType({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await setDayTypeRequest(payload);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateDay({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await updateDayRequest(payload);
      showSuccessToast('Attendances created successfully');
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async downloadReport({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await downloadReportRequest(payload);
      const blob = new Blob([data], { type: data.type })
      downloadFile(blob, `report-${payload}.csv`);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async removeAttendance({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      await removeAttendanceRequest(payload);
      showSuccessToast('Attendance has been removed successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getWorkShedules({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getWorkShedulesRequest(payload);
      commit('SET_TIMELINES', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  // new version attendance api

  async storeAttendance({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await createAttendanceRequest(payload);
      return new Promise((resolve) => {
        resolve(data)
      })
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getProfileAttendances({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await getMyAttendances(payload.date);
      commit('SET_PROFILE_ATTENDANCES', data)
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateAttendance({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await updateAttendance({id: payload.id, data: payload.data});
      return new Promise((resolve) => {
        resolve(data)
      })
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deleteAttendance({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await deleteAttendance({id: payload.id, data: payload.data});
      return new Promise((resolve) => {
        resolve(data)
      })
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getAttendancesBeta({ commit }, params) {
    commit('SET_ATTENDANCES', [])
    commit('SET_EMPLOYEES', [])
    try {
      commit('SET_LOADING', true);
      const data = await getAttendances(params);
      if (data.code === 200) {
        commit('SET_EMPLOYEES', data.data.employees)
        commit('SET_ATTENDANCES', data.data.attendance)
        commit('SET_MONTHS', data.data.month)
        commit('SET_HOLIDAYS', data.data.holiday)
        return new Promise((resolve) => {
          resolve(true)
        })
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getDayType({commit}) {
    try {
      const data = await getTypesOfDayRequest()
      commit('SET_DAY_TYPES', { name: 'day_types', data })
    } catch (e) {
      return e
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async getLocations({commit}) {
    try {
      const {code, data} = await getLocations()
      if (code === 200) {
        commit('SET_DAY_TYPES', { name: 'locations', data })
      }
    } catch (e) {
      return e
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async updatePeriod({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const {code, data} = await editPeriodRequest(payload);
      if (code === 200) {
        return data
      } else {
        return this._vm.$showErrorToast(data?.message ?? 'Something went wrong')
      }

    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
}

export const getters = {
  employeesItems(state) {
    return state.employees.map((emplyee) => {
      const items = state.items.filter(({ employee_uid: id }) => id === emplyee.uid);

      const allItems = state.calendar.map((day) => {
        const findedItem = items.find((item) => moment(item.date).isSame(moment(day.date)));
        if (!findedItem) {
          return day;
        }

        return findedItem;
      });

      return {
        ...emplyee,
        items: allItems
      }
    })
  },
  filters(state){
    return state.filters
  },
  isVacationHoursSpentType(state) {
    return state.vacationHoursType === VACATION_HOURS_TYPE.SPENT;
  }
};
