import {
  getLeaderboardRequest,
} from '@/api/leaderboard';

export const state = {
  list: [],
  loading: false
}

export const mutations = {
  SET_LIST(state, list) {
    state.list = list
  },
  SET_LOADING(state, value) {
    state.loading = value;
  }
}

export const actions = {
  async getLeaderboard({ commit }, params) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getLeaderboardRequest(params);
      commit('SET_LIST', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
}


export const getters = {
  leaderboardList(state) {
    return state.list;
  },
}
