import * as filesRequests from '@/api/files';
import * as purchaseRequsts from '@/api/purchases';

import { downloadFile } from '@/utils/files';
import { showSuccessToast } from "@/utils/toasts";

export const state = {
  purchases: [],
  totalItems: 0,
  purchase: null,
  loading: false,
  categories: [],
  tabs: [],
  excludeCategories: []
}

export const mutations = {
  SET_LIST(state, {key, value}) {
    state[key] = value
  },
  SET_PURCHASES(state, list) {
    state.purchases = list;
  },
  SET_TOTAL_RECORDS(state, count) {
    state.totalItems = count;
  },
  SET_CATEGORIES(state, list) {
    state.categories = list;
  },
  SET_TABS(state, tabs) {
    state.tabs = tabs;
  }
}

export const actions = {
  async getPurchases({ commit }, params) {
    try {
      const response = await purchaseRequsts.getPurchasesRequest(params);
      if (response.code === 200 && response.data) {
        commit('SET_PURCHASES', response.data?.items?.data ?? []);
        commit('SET_TOTAL_RECORDS', response.data?.items?.total ?? 0);
        commit('SET_TABS', response.data?.tabs ?? {});
        commit('SET_LIST', { key: 'excludeCategories', value: response.data?.exclusionCategories ?? [] })
      }
    } catch (e) {
      console.error(e);
    }
  },
  clearPurchases({ commit }) {
    commit('SET_PURCHASES', []);
  },
  async createPurchase(_, payload) {
    try {
      const response = await purchaseRequsts.createPurchaseRequest(payload);
      if (response.code === 200) {
        showSuccessToast('Purchase created successfully');
      }
      return response;
    } catch (e) {
      throw e;
    }
  },
  async updatePurchase(_, { id, data }) {
    try {
      const response = await purchaseRequsts.updatePurchaseRequest(id, data);
      if (response.code === 200) {
        showSuccessToast('Purchase updated successfully');
      }
    } catch (e) {
      throw e;
    }
  },
  async updatePurchaseStatus(_, payload) {
    try {
      const response = await purchaseRequsts.updatePurchaseStatusRequest(payload);
      if (response.code === 200){
        showSuccessToast('Purchase updated successfully');
      }
    } catch (e) {
      console.error(e);
    }
  },
  async deletePurchase(_, payload) {
    try {
      const response = await purchaseRequsts.deletePurchaseRequest(payload);
      if (response.code === 200) {
        showSuccessToast('Purchase deleted successfully');
      }
    } catch (e) {
      console.error(e);
    }
  },
  async getPurchaseCategories({ commit }) {
    try {
      const response = await purchaseRequsts.getPurchaseCategoriesRequest();
      if (response.code === 200 && response.data) {
        commit('SET_CATEGORIES', response.data);
      }
    } catch (e) {
      console.error(e);
    }
  },
  async createPurchaseCategory(_, payload) {
    try {
      const response = await purchaseRequsts.createPurchaseCategoryRequest(payload);
      if (response.code === 200) {
        showSuccessToast('Purchase category created successfully');
      }
    } catch (e) {
      console.error(e);
    }
  },
  async deletePurchaseCategory(_, payload) {
    try {
      const response = await purchaseRequsts.deletePurchaseCategoryRequest(payload);
      if (response.code === 200) {
        showSuccessToast('Purchase category deleted successfully');
      }
    } catch (e) {
      console.error(e);
    }
  },
  async updatePurchaseCategory(_, { id, data }) {
    try {
      const response = await purchaseRequsts.updatePurchaseCategoryRequest(id, data);
      if (response.code === 200) {
        showSuccessToast('Purchase category updated successfully');
      }
    } catch (e) {
      console.error(e);
    }
  },
  async uploadPurchaseFiles(_, payload) {
    try {
      await filesRequests.uploadFilesRequest(payload);
    } catch (e) {
      console.error(e);
    }
  },
  async deletePurchaseFiles(_, payload) {
    try {
      await filesRequests.deleteFilesRequest(payload);
    } catch (e) {
      console.error(e);
    }
  },
  async downloadPurchaseFiles(_, { id, fileName }) {
    try {
      const response = await filesRequests.getFileRequest(id);
      const blob = new Blob([response], { type: response.type })
      downloadFile(blob, fileName);
    } catch (e) {
      console.error(e);
    }
  },
  async exportToCsvPurchase(_, payload) {
    try {
      const response = await purchaseRequsts.downloadCsvPurchasesRequest(payload);
      const blob = new Blob([response], { type: response.type })
      downloadFile(blob, 'requisitions.csv');
    } catch (e) {
      console.error(e);
    }
  },
}
