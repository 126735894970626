import {
  getBookRequest,
  getBooksRequest,
  takeBookRequest,
  likeBookRequest,
  createBookRequest,
  updateBookRequest,
  returnBookRequest,
  deleteBookRequest,
  getNewestBooksRequest,
  uploadBookCoverRequest,
  getInvetoryCodeRequest,
  getLibrarySettingsRequest,
  getLibraryLanguagesRequest,
  downloadLibraryCSVRequest,
  getLibraryCategoriesRequest,
  createLibraryCategoryRequest,
  updateLibraryCategoryRequest,
  deleteLibraryCategoryRequest,
  createLibraryLanguageRequest,
  updateLibraryLanguageRequest,
  deleteLibraryLanguageRequest,
  getEmployeeTakedBooksRequest
} from '@/api/library';
import { downloadFile } from '@/utils/files';
import { showSuccessToast } from "@/utils/toasts";

export const state = {
  list: [],
  loading: false,
  totalItems: 0,
  book: {},
  employees: [],
  languages: [],
  categories: [],
  newest: [],
  takedBooks: [],
}

export const mutations = {
  SET_LIST(state, list) {
    state.list = list
  },
  SET_LOADING(state, value) {
    state.loading = value;
  },
  SET_TOTAL_RECORDS(state, count) {
    state.totalItems = count;
  },
  SET_BOOK(state, book) {
    state.book = book;
  },
  SET_EMPLOYEES(state, employees) {
    state.employees = employees;
  },
  SET_LANGUAGES(state, languages) {
    state.languages = languages;
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_NEWEST(state, list) {
    state.newest = list;
  },
  SET_TAKED_BOOKS(state, list) {
    state.takedBooks = list;
  }
}

export const actions = {
  async createBook(_, payload) {
    try {
      const response = await createBookRequest(payload);
      showSuccessToast('Book added successfully');
      return response?.data;
    } catch (e) {
      console.error(e);
    }
  },
  async updateBook(_, payload) {
    try {
      const response = await updateBookRequest(payload.id, payload.data);
      showSuccessToast('Book updated successfully');
      return response?.data;
    } catch (e) {
      console.error(e);
    }
  },
  async uploadBookCover(_, { id, data }) {
    try {
      await uploadBookCoverRequest(id, data);
    } catch (e) {
      console.error(e);
    }
  },
  async deleteBook(_, payload) {
    try {
      await deleteBookRequest(payload);
      showSuccessToast('Book deleted successfully');
    } catch (e) {
      console.error(e);
    }
  },
  async getBooks({ commit }, params) {
    try {
      commit('SET_LOADING', true);
      const response = await getBooksRequest(params);
      if (response.code === 200 && response.data) {
        commit('SET_LIST', response.data?.data || []);
        commit('SET_TOTAL_RECORDS', response.data?.total || 0);
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getLibrarySettings({ commit }) {
    try {
      const response = await getLibrarySettingsRequest();
      commit('SET_EMPLOYEES', response?.employees ?? []);
      commit('SET_LANGUAGES', response?.libraryLanguages ?? []);
      commit('SET_CATEGORIES', response?.libraryCategories ?? []);
    } catch (e) {
      console.error(e);
    }
  },
  async getNewestBooks({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getNewestBooksRequest();
      commit('SET_NEWEST', data || []);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getBook({ commit }, id) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getBookRequest(id);
      commit('SET_BOOK', data);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async takeBook({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      await takeBookRequest(id, data);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async returnBook({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      await returnBookRequest(payload);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  clearBook({ commit }) {
    commit('SET_BOOK', {});
  },
  async getCurrentInvetoryCode() {
    try {
      const response = await getInvetoryCodeRequest();
      return response?.data ?? null;
    } catch (e) {
      throw new Error(e)
    }
  },
  async downloadLibraryCSV(_, payload) {
    try {
      const data = await downloadLibraryCSVRequest(payload);
      const blob = new Blob([data], { type: data.type })
      downloadFile(blob, 'library.csv');
    } catch (e) {
      console.error(e);
    }
  },
  async getLibraryCategories({ commit }) {
    try {
      const response = await getLibraryCategoriesRequest();
      if (response.code === 200 && response.data) {
        commit('SET_CATEGORIES', response.data);
      }
    } catch (e) {
      console.log(e);
    }
  },
  async getLibraryLanguages({ commit }) {
    try {
      const response = await getLibraryLanguagesRequest();
      if (response.code === 200 && response.data) {
        commit('SET_LANGUAGES', response.data);
      }
    } catch (e) {
      console.log(e);
    }
  },
  async createLibraryCategory(_, payload) {
    try {
      const { code } = await createLibraryCategoryRequest(payload);
      if (code === 200) {
        showSuccessToast('Category created successfully!');
      }
    } catch (e) {
      console.error(e);
    }
  },
  async updateLibraryCategory(_, { id, data }) {
    try {
      const { code } = await updateLibraryCategoryRequest(id, data);
      if (code === 200) {
        showSuccessToast('Category updated successfully!');
      }
    } catch (e) {
      console.error(e);
    }
  },
  async deleteLibraryCategory(_, payload) {
    try {
      await deleteLibraryCategoryRequest(payload);
      showSuccessToast('Category deleted successfully');
    } catch (e) {
      console.error(e);
    }
  },
  async createLibraryLanguage(_, payload) {
    try {
      const { code } = await createLibraryLanguageRequest(payload);
      if (code === 200) {
        showSuccessToast('Language created successfully!');
      }
    } catch (e) {
      console.error(e);
    }
  },
  async updateLibraryLanguage(_, { id, data }) {
    try {
      const { code } = await updateLibraryLanguageRequest(id, data);
      if (code === 200) {
        showSuccessToast('Language updated successfully!');
      }
    } catch (e) {
      console.error(e);
    }
  },
  async deleteLibraryLanguage(_, payload) {
    try {
      await deleteLibraryLanguageRequest(payload);
      showSuccessToast('Language deleted successfully');
    } catch (e) {
      console.error(e);
    }
  },
  async likeBook(_, payload) {
    try {
      await likeBookRequest(payload);
    } catch (e) {
      console.error(e);
    }
  },
  async getEmployeeTakedBooks({ commit }) {
    try {
      const response = await getEmployeeTakedBooksRequest();
      if (response.code === 200 && response.data) {
        commit('SET_TAKED_BOOKS', response.data);
      }
    } catch (e) {
      console.error(e);
    }
  }
}
