import request from '@/utils/request';

export const getRequests = (data) => request.get('/bimi-coins/requests', { params: data });

export const createRequest = (data) => request.post('/bimi-coins/request', data);

export const updateRequest = (id, data) => request.post(`/bimi-coins/request/${id}`, data);

export const deleteRequest = (id, data) => request.post(`/bimi-coins/request/delete/${id}`, data);

//export const getBalanceRequest = (employee_uid, params) => request.get(`/bimi-coins/${employee_uid}/balance`, {params});

export const getBalanceRequest = () => request.get(`/bimi-coins/balance`);

export const getTypesRequest = () => request.get('/bimi-coins/types');

export const getAppsRequest = () => request.get('/bimi-coins/apps');

export const getPlatformsRequest = () => request.get('/bimi-coins/platforms');

export const getProductsRequest = () => request.get('/bimi-coins/products');