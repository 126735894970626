import {
  getRequests,
  createRequest,
  updateRequest,
  deleteRequest,
  getBalanceRequest,
  getTypesRequest,
  getAppsRequest,
  getPlatformsRequest,
  getProductsRequest
} from '@/api/balance-details';
import {showSuccessToast} from "@/utils/toasts";
import {showErrorToast} from "@/utils/toasts";

export const state = {
  requests: [],
  types: [],
  balance: '',
  loading: false
}

export const mutations = {
  SET_REQUESTS(state, list) {
    state.requests = list
  },
  SET_BALANCE(state, list) {
    state.balance = list
  },
  SET_TYPES(state, list) {
    state.types = list
  },
  SET_APPS(state, list) {
    state.apps = list
  },
  SET_PLATFORMS(state, list) {
    state.platforms = list
  },
  SET_PRODUCTS(state, list) {
    state.products = list
  },
  SET_LOADING(state, value) {
    state.loading = value;
  }
}

export const actions = {
  async getBalanceRequest({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getBalanceRequest();
      commit('SET_BALANCE', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getTypesRequest({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getTypesRequest();
      commit('SET_TYPES', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getAppsRequest({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getAppsRequest();
      commit('SET_APPS', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getPlatformsRequest({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getPlatformsRequest();
      commit('SET_PLATFORMS', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getProductsRequest({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getProductsRequest();
      commit('SET_PRODUCTS', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  // ------------------------------------------------------------
  async getRequests({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getRequests(payload);
      commit('SET_REQUESTS', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async createRequest({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await createRequest(payload);
      if (data.code === 200) {
        //showSuccessToast('Request created successfully');
        showSuccessToast(data.message);
      }else{
        showErrorToast(data.message);
      }
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateRequest({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      const result = await updateRequest(id, data);
      if (result.code === 200) {
        showSuccessToast('Request updated successfully');
      }
      return result;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deleteRequest({ commit }, { data, id }) {
    try {
      commit('SET_LOADING', true);
      await deleteRequest(id, data);
      showSuccessToast('Request deleted successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
}


export const getters = {
  balance(state) {
    return state.balance;
  },
  types(state) {
    return state.types;
  },
  apps(state) {
    return state.apps;
  },
  platforms(state) {
    return state.platforms;
  },
  products(state) {
    return state.products;
  },
  requestList(state) {
    return state.requests;
  },
}
