import { showSuccessToast, showErrorToast } from '@/utils/toasts';
import { getUserConfirmation } from '@/utils/dialog';
import {
  fetchPlatforms,
  showPlatform,
  createPlatform,
  updatePlatform,
  deletePlatform,
  updatePlatformsOrderRequest
} from '@/api/platforms'

export const state = {
  platforms: [],
  platform: null
}

export const getters = {
  getPlatforms: state => state.platforms,
  getPlatform: state => state.platform,
}

export const mutations = {
  SET_PLATFORMS(state, platforms) {
    state.platforms = platforms
  },
  SET_PLATFORM(state, platform) {
    state.platform = platform
  },
}

export const actions = {
  async fetchPlatforms({ commit }, payload) {
    try {
      const {data} = await fetchPlatforms(payload)
      commit('SET_PLATFORMS', data)
    } catch (error) {
      showErrorToast(error)
    }
  },
  async showPlatform({ commit }, id) {
    try {
      const data = await showPlatform(id)
      commit('SET_PLATFORM', data)
    } catch (error) {
      showErrorToast(error)
    }
  },
  async createPlatform(_, platform) {
    try {
      const result = await createPlatform(platform)
      if (result) {
        showSuccessToast('Platform created successfully')
      }
      return result
    } catch (error) {
      showErrorToast(error)
    }
  },
  async updatePlatform(_, platform) {
    try {
      const result = await updatePlatform(platform)
      if (result) {
        showSuccessToast('Platform updated successfully')
      }
      return result
    } catch (error) {
      showErrorToast(error)
    }
  },
  async deletePlatform(_, id) {
    try {
      const { isConfirmed } = await getUserConfirmation()

      if (isConfirmed) {
        await deletePlatform(id)
        showSuccessToast('The platform has been deleted.')
      }
    } catch (error) {
      showErrorToast(error)
    }
  },
  async updatePlatformsOrder(_, payload) {
    try {
      await updatePlatformsOrderRequest(payload);
    } catch (err) {
      console.error(err);
    }
  }
}
