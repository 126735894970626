import request from '@/utils/request';

export const getBooksRequest = (params) => request.get('/library/books', { params });

export const downloadLibraryCSVRequest = (params) => request.get('/library/books', {
  params: {
    ...params,
    csv: 1,
  },
  responseType: 'blob'
});

export const getLibrarySettingsRequest = () => request.get('/library/settings');

export const getNewestBooksRequest = () => request.get('/library/new-books');

export const getBookRequest = (id) => request.get(`/library/books/${id}`);

export const createBookRequest = (data) => request.post('/library/books', data);

export const deleteBookRequest = (id) => request.delete(`/library/books/${id}`);

export const updateBookRequest = (id, data) => request.put(`/library/books/${id}`, data);

export const uploadBookCoverRequest = (id, data) => request.post(`library/books/${id}/upload`, data);

export const takeBookRequest = (id, data) => request.post(`library/books/${id}/assign`, data);

export const returnBookRequest = (id) => request.put(`library/books/${id}/unassign`);

export const getInvetoryCodeRequest = () => request.get('/library/inventory-code');

export const getLibraryLanguagesRequest = () => request.get('/library/languages');

export const getLibraryCategoriesRequest = () => request.get('/library/categories');

export const createLibraryCategoryRequest = (data) => request.post('/library/categories', data);

export const updateLibraryCategoryRequest = (id, data) => request.put(`/library/categories/${id}`, data);

export const deleteLibraryCategoryRequest = (id) => request.delete(`/library/categories/${id}`);

export const createLibraryLanguageRequest = (data) => request.post('/library/languages', data);

export const updateLibraryLanguageRequest = (id, data) => request.put(`/library/languages/${id}`, data);

export const deleteLibraryLanguageRequest = (id) => request.delete(`/library/languages/${id}`);

export const likeBookRequest = (data) => request.post('/library/review', data);

export const getEmployeeTakedBooksRequest = () => request.get('/library/employee-books');
