import {
  sendConsoleCommandRequest
} from '@/api/settings';

export const state = {
  loading: false
}

export const mutations = {
  SET_LOADING(state, value) {
    state.loading = value;
  }
}

export const actions = {
  async sendConsoleCommand({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data, code } = await sendConsoleCommandRequest(payload);
      if (data && code === 200) {
        this._vm.$showSuccessToast('Console Command execute successfully')
      } else {
        this._vm.$showErrorToast(data.message.message);
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
}