import Swal from "sweetalert2"

export const showSuccessToast = (message) => {
  Swal.fire({
    toast: true,
    timer: 2000,
    icon: 'success',
    title: 'Success',
    position: 'bottom',
    text: message,
    timerProgressBar: false,
    showConfirmButton: false,
  });
};

export const showErrorToast = (message, title = 'Error') => {
  Swal.fire({
    title,
    toast: true,
    timer: 2000,
    icon: 'error',
    position: 'bottom',
    html: message,
    timerProgressBar: false,
    showConfirmButton: false,
  });
};
