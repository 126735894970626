import request from '@/utils/request';

export const getCoinRequests = (data) => request.get('/bimi-coins/manage/requests', { params: data });

//export const createCoinRequest = (data) => request.post('/coin-request', data);

export const updateCoinRequest = (id, data) => request.post(`/bimi-coins/manage/request/${id}`, data);

//export const deleteCoinRequest = (id, data) => request.post(`/coin/${id}`, data);

export const getTypesRequest = (params) => request.get('/bimi-coins/types', { params });

export const getStatusesRequest = () => request.get('/bimi-coins/statuses');

export const addCoinRequest = (data) => request.post('/bimi-coins/change/balance', data);

export const downloadCoinCsvRequest = (params) => request.get('/bimi-coins/manage/requests', {
    params: {
        ...params,
        csv: 1,
    },
    responseType: 'blob'
});
