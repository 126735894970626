<script>
import router from "@/router";
import CustomSideBar from "../../components/CustomSidebar";
import SupportBlock from "@/components/SupportBlock.vue";

/**
 * Vertical layout
 */
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
      require: false
    }
  },
  components: { CustomSideBar, SupportBlock },
  data() {
    return {
      isMenuCondensed: false,
    };
  },
  computed: {
    isPublicPage() {
      switch (this.$route.name) {
        case 'FillProfileFully':
          return true;
        case 'FillProfileShortly':
          return true;
        default:
            return false;
      }
    }
  },
  created: () => {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");
    document.body.classList.remove("auth-body-bg");
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
  }
};
</script>

<template>
  <div>
    <div id="layout-wrapper">
      <CustomSideBar/>
      <div :class="{'main-content': !isPublicPage}">
        <div class="page-content" :class="{'pt-1': isPublicPage}">
          <!-- Start Content-->
          <div class="container-fluid">
            <slot />
          </div>
        </div>
      </div>
      <SupportBlock />
    </div>
  </div>
</template>

<style>
.close-menu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  display: none;
}

@media (max-width: 991px) {
  body.sidebar-enable .close-menu {
    display: block;
  }
}
</style>
