import Vue from 'vue';
import * as dismissalRequsts from '@/api/dismissal-warning';

export const state = {
  warnings: [],
  templates: [],
  selected: null,
  totalItems: 0,
}

export const mutations = {
  SET_WARNINGS(state, list) {
    state.warnings = list;
  },
  SET_TEMPlATES(state, list) {
    state.templates = list
  },
  SET_SELECTED(state, value) {
    state.selected = value;
  },
  SET_TOTAL_RECORDS(state, count) {
    state.totalItems = count;
  },
}

export const actions = {
  async getDismissalWarnings({ commit }, payload) {
    try {
      const response = await dismissalRequsts.getDismissalWarningsRequest(payload);
      if (response.code === 200 && response.data?.data) {
        commit('SET_WARNINGS', response.data.data);
        commit('SET_TOTAL_RECORDS', response.data.total ?? 0);
      }
    } catch (e) {
      console.error(e);
    }
  },
  async getDismissalWarning({ commit }, payload) {
    try {
      const response = await dismissalRequsts.getDismissalWarningRequest(payload);
      if (response.code === 200 && response.data) {
        commit('SET_SELECTED', response.data);
      }
    } catch (e) {
      console.error(e);
    }
  },
  clearDismissalWarning({ commit }) {
    commit('SET_SELECTED', null);
  },
  async createDismissalWarning(_, payload) {
    try {
      const response = await dismissalRequsts.createDismissalWarningRequest(payload);
      if (response.code === 200) {
        Vue.prototype.$showSuccessToast('Dismissal warning has been successfully created');
      }
    } catch (e) {
      console.error(e);
    }
  },
  async getDismissalTemplates({ commit }) {
    try {
      const response = await dismissalRequsts.getDismissalTemplatesRequest();
      if (response.code === 200 && response.data) {
        commit('SET_TEMPlATES', response.data);
      }
    } catch (e) {
      console.error(e);
    }
  },
  async createDismissalTemplate(_, payload) {
    try {
      const response = await dismissalRequsts.createDismissalTemplateRequest(payload);
      if (response.code === 200) {
        Vue.prototype.$showSuccessToast('Template has been successfully created');
      }
      return response.data;
    } catch (e) {
      console.error(e);
    }
  },
  async updateDismissalWarning(_, { id, data }) {
    try {
      await dismissalRequsts.updateDismissalWarningRequest(id, data);
    } catch (e) {
      console.error(e);
    }
  },
  async updateDismissalTemplate(_, payload) {
    try {
      const response = await dismissalRequsts.updateDismissalTemplateRequest(payload.id, payload.data);
      if (response.code === 200) {
        Vue.prototype.$showSuccessToast('Template has been successfully updated');
      }
      return response.data;
    } catch (e) {
      console.error(e);
    }
  },
  async deleteDismissalTemplate(_, payload) {
    try {
      const response = await dismissalRequsts.deleteDismissalTemplateRequest(payload);
      if (response.code === 200) {
        Vue.prototype.$showSuccessToast('Template has been successfully deleted');
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export const getters = {
  activeTemplates(state) {
    return state.templates
      .filter(({ deleted_at }) => !deleted_at)
      .sort((a, b) => a.title.localeCompare(b.title));
  }
}
