import {
    getManagers,
    getSubordinates,
    storeFeedback,
    getAllFeedbacks,
    getReceivedFeedbacks,
    getMyFeedbacks,
    getAllSentFeedback,
    getAllReceivedFeedback,
    getAllEmployeesRequest,
    getSentFeedbacksByIdRequest,
    getReceivedFeedbacksByIdRequest
} from "../../api/weekly-feedback";
import { showErrorToast, showSuccessToast } from "../../utils/toasts";

const parseFeedbacks = ({ have_feedback: feedbacks, others }, type) => {
  const othersData = others.map(item => ({
    is_regular_colleage: true,
    last_date: null,
    [type === 'sentFeedbacks' ? 'recipient' : 'author']: item,
    total: 0
  }));
  return [...feedbacks, ...othersData].sort((a,b) => {
    const field = type === 'sentFeedbacks' ? 'recipient' : 'author';
    return a[field].name.localeCompare(b[field].name);
  });
}

export const state = {
  loading: false,
  managers: [],
  subordinates: [],
  allFeedbacks: [],
  sentFeedbacks: [],
  receivedFeedbacks: [],
  myFeedbacks: [],
  totalItems: 0,
  feedbackStatus: false,
  dashboard: {
    sentFeedbacks: [],
    receivedFeedbacks: [],
    savedFeedbacks: []
  },
  filters: {
    allFeedbacks: null,
    receivedFeedbacks: null,
    sentFeedbacks: null
  },
  employees: []
}


export const mutations = {
    SET_MANAGERS (state, managers) {
        state.managers = managers
    },
    SET_SUBORDINATES (state, subordinates) {
        state.subordinates = subordinates
    },
    UPDATE_LOADING (state, status) {
        state.loading = status
    },
    SET_ALL_FEEDBACKS (state, feedbacks) {
        state.allFeedbacks = feedbacks
    },
    SET_RECEIVED_FEEDBACKS (state, feedbacks) {
        state.receivedFeedbacks = feedbacks
    },
    SET_SENT_FEEDBACKS (state, feedbacks) {
      state.sentFeedbacks = feedbacks;
  },
    SET_MY_FEEDBACKS (state, feedbacks) {
        state.myFeedbacks = feedbacks
    },
    SET_TOTAL_ROW (state, count) {
        state.totalItems = count
    },
    SET_FILTER_FOR_ALL_FEEDBACKS (state, filters) {
        state.filters.allFeedbacks = { ...filters }
    },
    SET_FILTER_FOR_RECEIVED (state, filters) {
        state.filters.receivedFeedbacks = { ...filters }
    },
    SET_FILTER_FOR_SENT (state, filters) {
        state.filters.sentFeedbacks = { ...filters }
    },
    SET_FEEDBACK_STATUS (state, status) {
        state.feedbackStatus = status
    },
    SET_DASHBOARD_DATA (state, obj) {
        // inside obj.data.others employees who are direct colleagues but no feedback
        const othersData = obj.data?.others.map(item => {
            if (obj.key === 'sentFeedbacks') {
                return {
                    is_regular_colleage: true,
                    last_date: null,
                    recipient: item,
                    total: 0
                }
            }
            if (obj.key === 'receivedFeedbacks') {
                return {
                    is_regular_colleage: true,
                    last_date: null,
                    author: item,
                    total: 0
                }
            }
        })
        state.dashboard[obj.key] = [...obj.data?.have_feedback, ...othersData].sort((a,b) => {
            switch (obj.key) {
                case 'sentFeedbacks':
                    return a.recipient.name.localeCompare(b.recipient.name)
                case 'receivedFeedbacks':
                    return a.author.name.localeCompare(b.author.name)
            }
        })
    },

    UPDATE_SAVED_FEEDBACKS (state, {text, uid}) {
        if (state.dashboard.savedFeedbacks.length > 0 && state.dashboard.savedFeedbacks.some(e => e.uid === uid)) {
            state.dashboard.savedFeedbacks.find(el => el.uid === uid).text = text
        } else {
            state.dashboard.savedFeedbacks.push({text, uid})
        }
    },

    DELETE_SAVED_FEEDBACK (state, id) {
        if (state.dashboard.savedFeedbacks.some(e => e.uid === id)) {
            state.dashboard.savedFeedbacks = state.dashboard.savedFeedbacks.filter(el => el.uid !== id)
        }
    },
    SET_EMPLOYEES(state, list) {
      state.employees = list;
    },
}

export const actions = {
    async getManagers ({commit}) {
        commit('UPDATE_LOADING', true)
        try {
            const data = await getManagers()
            if (data.code === 200) {
                commit('SET_MANAGERS', data.data)
                return new Promise((resolve) => {
                    resolve(true)
                })
            } else {
                showErrorToast(`${data.message}`)
            }
        } catch (e) {
            showErrorToast(`Something went wrong`)
        } finally {
            commit('UPDATE_LOADING', false)
        }
    },
    async getSubordinates ({commit}) {
        commit('UPDATE_LOADING', true)
        try {
            const data = await getSubordinates()
            if (data.code === 200) {
                commit('SET_SUBORDINATES', data.data)
                return new Promise((resolve) => {
                    resolve(true)
                })
            } else {
                showErrorToast(`${data.message}`)
            }
        } catch (e) {
            showErrorToast(`Something went wrong`)
        } finally {
            commit('UPDATE_LOADING', false)
        }
    },
    async storeFeedback ({commit}, payload) {
        commit('UPDATE_LOADING', true)
        try {
            const data = await storeFeedback(payload)
            if (data.code === 200) {
                showSuccessToast(`${data.data.message}`)
                return new Promise((resolve) => {
                    resolve(true)
                })
            } else {
                showErrorToast(`${data.message}`)
            }
        } catch (e) {
            showErrorToast(`Something went wrong`)
        } finally {
            commit('UPDATE_LOADING', false)
        }
    },
    async getAllFeedbacks ({commit}, payload) {
        commit('UPDATE_LOADING', true)
        try {
            const data = await getAllFeedbacks(payload)
            if (data.code === 200) {
                commit('SET_ALL_FEEDBACKS', data.data)
                commit('SET_TOTAL_ROW', data.data.total)
            } else {
                showErrorToast(`${data.message}`)
            }
        } catch (e) {
            showErrorToast(`Something went wrong`)
        } finally {
            commit('UPDATE_LOADING', false)
        }
    },
    async getReceivedFeedbacks ({commit}, payload) {
        commit('UPDATE_LOADING', true)
        try {
            const data = await getReceivedFeedbacks(payload)
            if (data.code === 200) {
                commit('SET_RECEIVED_FEEDBACKS', data.data)
                commit('SET_TOTAL_ROW', data.data.total)
            } else {
                showErrorToast(`${data.message}`)
            }
        } catch (e) {
            showErrorToast(`Something went wrong`)
        } finally {
            commit('UPDATE_LOADING', false)
        }
    },
    async getMyFeedbacks ({commit}, payload) {
        commit('UPDATE_LOADING', true)
        try {
            const data = await getMyFeedbacks(payload)
            if (data.code === 200) {
                commit('SET_MY_FEEDBACKS', data.data)
                commit('SET_TOTAL_ROW', data.data.total)
            } else {
                showErrorToast(`${data.message}`)
            }
        } catch (e) {
            showErrorToast(`Something went wrong`)
        } finally {
            commit('UPDATE_LOADING', false)
        }
    },
    async getAllSentFeedback ({commit}) {
        commit('UPDATE_LOADING', true)
        try {
            const data = await getAllSentFeedback()
            if (data.code === 200) {
                commit('SET_DASHBOARD_DATA', {data: data.data, key: 'sentFeedbacks'})
            } else {
                showErrorToast(`${data.message}`)
            }
        } catch (e) {
            showErrorToast(`Something went wrong`)
        } finally {
            commit('UPDATE_LOADING', false)
        }
    },
    async getAllReceivedFeedback ({commit}) {
        commit('UPDATE_LOADING', true)
        try {
            const data = await getAllReceivedFeedback()
            if (data.code === 200) {
                commit('SET_DASHBOARD_DATA', {data: data.data, key: 'receivedFeedbacks'})
            } else {
                showErrorToast(`${data.message}`)
            }
        } catch (e) {
            showErrorToast(`Something went wrong`)
        } finally {
            commit('UPDATE_LOADING', false)
        }
    },
  async fetchAllEmployees({ commit }) {
    try {
      const response = await getAllEmployeesRequest();
      if (response && response.code === 200 && response.data) {
        commit('SET_EMPLOYEES', response.data);
      }
      return response;
    } catch (e) {
      console.error(e);
    }
  },
  async fetchSentFeedbackById({ commit }, payload) {
    try {
      const response = await getSentFeedbacksByIdRequest(payload);
      if (response && response.code === 200 && response.data) {
        commit('SET_SENT_FEEDBACKS', parseFeedbacks(response.data, 'sentFeedbacks'));
      }
      return response;
    } catch (e) {
      console.error(e);
    }
  },
  async fetchReceivedFeedbackById({commit}, payload) {
    try {
      const response = await getReceivedFeedbacksByIdRequest(payload);
      if (response && response.code === 200 && response.data) {
        commit('SET_RECEIVED_FEEDBACKS', parseFeedbacks(response.data, 'receivedFeedbacks'));
      }
      return response;
    } catch (e) {
      console.error(e);
    }
  }
}


