import {
    getAllDateInterval,
    setFavouriteEmployee,
    getAllEmployees
} from '@/api/hours-availability'

export const state = {
    departments: [],
    employees:[],
    loading: false
}

export const mutations = {
    SET_DEPARTMENTS(state, data) {
        state.departments = data
    },
    SET_EMPLOYEES(state, data) {
        state.employees = data.map(item => {
            return { ...item, isLoad: false }
        })
    },
    SET_LOADING (state, status) {
        state.loading = status
    },
    UPDATE_STATUS_EMPLOYEE(state, id) {
        state.employees.find(item => item.uid === id).is_favourite = !state.employees.find(item => item.uid === id).is_favourite
    },
    SET_FAVOURITE_EMPLOYEE(state, uid) {
        const departments = [...state?.departments ?? []]
        const isUserFavourite = state.employees.find(el => el.uid === uid)?.is_favourite ?? false
        let currentEmployee = null
        for (let i = 0; i < departments.length; i += 1) {
            if (departments[i].employees.some(el => el.uid === uid)) {
                currentEmployee = departments[i].employees.find(el => el.uid === uid)
            }
        }
        if (currentEmployee) {
            departments.forEach((el) => {
                if (el.department_name === 'Favourite') {
                    if (isUserFavourite) {
                        el.employees.push(currentEmployee)
                        el.employees.sort((a, b) => a.name.localeCompare(b.name))
                    } else {
                        el.employees = el.employees.filter(usr => usr.uid !== uid)
                    }
                } else if (el.department_name === currentEmployee?.department?.title) {
                    if (isUserFavourite) {
                        el.employees = el.employees.filter(usr => usr.uid !== uid)
                    } else {
                        el.employees.push(currentEmployee)
                        el.employees.sort((a, b) => a.name.localeCompare(b.name))
                    }
                }
            })
        }
        state.departments = departments
    },
    UPDATE_WAIT_STATUS_EMPLOYEE (state, { id, status }) {
        state.employees.find(item => item.uid === id).isLoad = status
    }
}

export const actions = {
    async getAllDateIntervals ({ commit }) {
        try {
            commit('SET_LOADING', true);
            const data = await getAllDateInterval()
            commit('SET_DEPARTMENTS', data)

            return new Promise((resolve) => {
                resolve(true)
            })
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_LOADING', false)
        }
    },
    async getAllEmployees ({ commit }) {
        try {
            const data = await getAllEmployees()
            commit('SET_EMPLOYEES', data.data)
            return new Promise((resolve) => {
                resolve(true)
            })
        } catch (e) {
            console.error(e);
        }
    },
    async updateFavouriteEmployee (__, payload) {
        try {
            await setFavouriteEmployee(payload)
            return new Promise((resolve) => {
                resolve(true)
            })
        } catch (e) {
            console.error(e);
        }
    }
}

export const getters = {
    employeeList (state) {
        return state.employees.filter(el => el.status === 'active').map(item => {
            return {
                uid: item.uid,
                name: item.name,
                department: item.department,
                position: item.position.title,
                photo: item.photo,
                isFavourite: item.is_favourite,
                isLoad: item.isLoad
            }
        })
    },
}
