import { saveAboutRequest, getAboutRequest } from '@/api/about';

export const state = {
  content: null,
  loading: false
}

export const mutations = {
  SET_ABOUT(state, value) {
    state.content = value;
  },
  SET_LOADING(state, value) {
    state.loading = value;
  }
}

export const actions = {
  async getAbout({ commit }, {key}) {
    try {
      commit('SET_LOADING', true);
      const res = await getAboutRequest(key);
      commit('SET_ABOUT', res.data.value);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async saveAbout({ commit }, {key, data}) {
    try {
      commit('SET_LOADING', true);
      const res = await saveAboutRequest(key, data);
      commit('SET_ABOUT', res.data.value);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },   
}