import {
  getCodesRequest,
  createCodeRequest,
  updateCodeRequest,
  deleteCodeRequest
} from '@/api/codes-management';
import {showSuccessToast} from "@/utils/toasts";

export const state = {
  codes: [],
  loading: false
}

export const mutations = {
  SET_CODES(state, list) {
    state.codes = list
  },
  SET_LOADING(state, value) {
    state.loading = value;
  }
}

export const actions = {
  async getCodes({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getCodesRequest(payload);
      commit('SET_CODES', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async createCode({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await createCodeRequest(payload);
      if (data.code === 200) {
        showSuccessToast('Code created successfully');
        return data;
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateCode({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      const result = await updateCodeRequest(id, data);
      if (result.code === 200) {
        showSuccessToast('Code updated successfully');
      }
      return result;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deleteCode({ commit }, { data, id }) {
    try {
      commit('SET_LOADING', true);
      await deleteCodeRequest(id, data);
      showSuccessToast('Code deleted successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
}


export const getters = {
  codeList(state) {
    return state.codes;
  },
}
