import * as OrgStruct from '@/api/org-struct';
import { showSuccessToast } from '@/utils/toasts';

export const state = {
  items: [],
  tree: [],
  item: null,
  message: null,
  statePositions: [],
};

export const getters = {
  getItems(state){
    return state.items;
  },
  getItem(state){
    return state.items;
  },
  getMessage(state){
    return state.message;
  },
  getStatePositions(state){
    return state.statePositions;
  },
};

export const actions = {
  async items({ commit }) {
    try {
      const { data } = await OrgStruct.getOrgStructRequest();
      if (data) {
        commit('SET_ITEMS', data);
      }
    } catch (e) {
      console.error(e);
    }
  },
  async getOrgStructureTree({ commit }) {
    try {
      const { data } = await OrgStruct.getOrgStructTreeRequest();
      if (data) {
        commit('SET_TREE', data);
      }
    } catch (e) {
      console.error(e);
    }
  },
  async statePositions({ commit }) {
    try {
      commit('SET_STATE_POSITIONS', []);
      const data = await OrgStruct.statePositionsRequest();
      await commit('SET_STATE_POSITIONS', data.data);
    } catch (e) {
      console.error(e);
    }
  },
  async create({ commit }, payload) {
    try {
      const { data } = await OrgStruct.createOrgStructRequest(payload);
      if (data) {
        commit('SET_MESSAGE', data);
        showSuccessToast('Structure created successfully');
      }
    } catch (e) {
      console.error(e);
    }
  },
  async update({ commit }, { id, data }) {
    try {
      const response = await OrgStruct.updateOrgStructRequest(id, data);
      if (response?.data) {
        commit('SET_MESSAGE', response?.data);
        showSuccessToast('Structure updated successfully');
      }
    } catch (e) {
      console.error(e);
    }
  },
  async delete({ commit }, id) {
    try {
      const response =await OrgStruct.deleteOrgStructRequest(id);
      await showSuccessToast('Structure deleted successfully');
      await commit('SET_MESSAGE', response?.data);
    } catch (e) {
      console.error(e);
    }
  },
};

export const mutations = {
  SET_ITEMS(state, items) {
    state.items = items;
  },
  SET_TREE(state, items) {
    state.tree = items;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
  SET_STATE_POSITIONS(state, items) {
    state.statePositions = items
  },
};

