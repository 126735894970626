import Swal from 'sweetalert2'

export const getUserConfirmation = async (msg, confirmButton) =>
  await Swal.fire({
    text: msg || 'Please confirm this action',
    confirmButtonText: confirmButton || 'Confirm',
    confirmButtonColor: '#34c38f',
    showCancelButton: true,
    cancelButtonColor: '#f46a6a',
    reverseButtons: true
  })
