import Vue from 'vue'

Vue.directive('load', (el, binding) => {
    try {
        if (binding.value) {
            const findLoader = el.getElementsByClassName('loader')
            if (findLoader.length > 0) {
                findLoader.forEach(el => {
                    el.remove()
                })
            }
            const divLoader = document.createElement('div')
            divLoader.classList.add('loader')
            const spanParent = document.createElement('span')
            const spanChild = document.createElement('span')

            spanParent.classList.add('spinner-border', 'text-primary')
            spanParent.style.width = '4rem'
            spanParent.style.height = '4rem'
            spanParent.style.borderWidth = '0.4rem'

            spanChild.classList.add('sr-only')
            spanChild.textContent = 'Loading...'
            spanParent.appendChild(spanChild)
            divLoader.appendChild(spanParent)
            el.append(divLoader)
        } else {
            const findLoader = (el?.getElementsByClassName('loader') || [])
            findLoader[0]?.remove()
        }
    } catch (e) {
        return false;
    }
})

Vue.directive('load-sm', (el, binding) => {
    try {
        if (binding.value.load) {
            const findSmLoader = el.getElementsByClassName('sm-loader')
            if (findSmLoader.length > 0) {
                findSmLoader.forEach(el => {
                    el.remove()
                })
            }
            const divLoader = document.createElement('div')
            divLoader.classList.add('sm-loader')
            divLoader.style.top = binding.value?.style.top ? binding.value?.style?.top + 'px' : 0 + 'px'
            divLoader.style.height = binding.value?.style.height ? binding.value?.style?.height + '%' : 100 + '%'
            const spanParent = document.createElement('span')
            const spanChild = document.createElement('span')

            spanParent.classList.add('spinner-border', 'text-primary')
            spanParent.style.width = '4rem'
            spanParent.style.height = '4rem'
            spanParent.style.borderWidth = '0.4rem'

            spanChild.classList.add('sr-only')
            spanChild.textContent = 'Loading...'
            spanParent.appendChild(spanChild)
            divLoader.appendChild(spanParent)
            el.append(divLoader)
        } else {
            const findLoader = (el?.getElementsByClassName('sm-loader') || [])
            findLoader[0]?.remove()
        }
    } catch (e) {
        return false;
    }
})