import request from '@/utils/request';

export const getPurchasesRequest = (params) => request.get('/purchases', { params });

export const downloadCsvPurchasesRequest = (params) => request.get('/purchases', {
  params: { ...params, csv: 1 },
  responseType: 'blob'
});

export const getPurchaseRequest = (id) => request.get('/purchases/' + id);

export const createPurchaseRequest = (data) => request.post('/purchases', data);

export const updatePurchaseRequest = (id, data) => request.put(`/purchases/${id}`, data);

export const deletePurchaseRequest = (id) => request.delete('/purchases/' + id);

export const updatePurchaseStatusRequest = (data) => request.post('/purchase-status', data);

export const updatePurchaseNoteRequest = (id, payload) => request.post('/purchases/change_note/' + id, payload);

export const getPurchaseCategoriesRequest = () => request.get('/purchase-categories');

export const createPurchaseCategoryRequest = (data) => request.post('/purchase-categories', data);

export const deletePurchaseCategoryRequest = (id) => request.delete(`/purchase-categories/${id}`);

export const updatePurchaseCategoryRequest = (id, data) => request.put(`/purchase-categories/${id}`, data);
