import moment from 'moment'
import Vue from "vue";
import Swal from "sweetalert2";
import { hasResourceRole } from "@/state/helpers";
import { isArray } from "lodash";


export const prototypes = () => {
    Vue.prototype.$moment = moment
    Vue.prototype.$shortText = (str, limit) => {
        return str.length > limit ? str.substr(0, limit) + '...' : str
    }
    Vue.prototype.$convertToFormData = (obj) => {
        const data = new FormData();
        for (const [key, value] of Object.entries(obj)) {
            if (Array.isArray(value)) {
                value.forEach((item, index) => data.append(`${key}[${index}]`, item));
            } else {
                data.append(key, value);
            }
        }

        return data;
    };

    Vue.prototype.$showErrorToast = (message, title = 'Error') => {
        Swal.fire({
            title,
            toast: true,
            timer: 2000,
            icon: 'error',
            position: 'top-end',
            html: message,
            timerProgressBar: false,
            showConfirmButton: false,
        });
    };

    Vue.prototype.$showSuccessToast = (message) => {
        Swal.fire({
            toast: true,
            timer: 2000,
            icon: 'success',
            title: 'Success',
            position: 'top-end',
            text: message,
            timerProgressBar: false,
            showConfirmButton: false,
        });
    }
    Vue.prototype.$hasAccess = (roles) => {
        return isArray(roles) ? roles.some((role) => hasResourceRole(role) || role === 'all') : false
    }
    Vue.prototype.$convertToFloat = (number = 0, count = 2, show) => {
        const convertNumber = Math.abs(parseFloat(number)).toFixed(count).toString()
        return (show && number < 0 ? '-' : '') +
            convertNumber.slice(0, count ? (-count -1) : convertNumber.length)
                .split('')
                .reverse()
                .map((e, i) => i % 3 === 0 && i ? e + ',' : e)
                .reverse()
                .join('')
            + convertNumber.slice(count ? (-count -1) : convertNumber.length)
    }
}
