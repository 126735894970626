import { getNotificationChecksAndCountsRequest } from '@/api/notifications';

export const state = {
  checksAndCounts: {}
};

export const mutations = {
  SET_CHECKS_AND_COUNTS(state, value) {
    state.checksAndCounts = value;
  }
};

export const actions = {
  async getNotificationChecksAndCounts({ commit }) {
    try {
      const response = await getNotificationChecksAndCountsRequest();
      if (response.code === 200 && response.data) {
        commit('SET_CHECKS_AND_COUNTS', response.data);
      }
    } catch (err) {
      console.error(err);
    } 
  }
};
