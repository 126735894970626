import {
  getBlogsRequest,
  getBlogRequest,
  createBlogRequest,
  updateBlogRequest,
  deleteBlogRequest,
  getBlogTagsRequest,
  createBlogTagRequest,
  deleteBlogTagRequest
} from '@/api/blogs';
import {showSuccessToast} from "@/utils/toasts";

export const state = {
  blogs: [],
  blog: null,
  tags: [],
  loading: false
}

export const mutations = {
  SET_BLOGS(state, list) {
    if (list.current_page === 1) {
      state.blogs = list
    } else {
      state.blogs.data.push(...list.data)
    }
  },
  SET_BLOG(state, list) {
    state.blog = list
  },
  SET_TAGS(state, list) {
    state.tags = list
  },
  SET_LOADING(state, value) {
    state.loading = value;
  }
}

export const actions = {
  async getBlogs({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getBlogsRequest(payload);
      commit('SET_BLOGS', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getBlog({ commit }, id) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getBlogRequest(id);
      commit('SET_BLOG', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async createBlog({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await createBlogRequest(payload);
      if (data.code === 200) {
        showSuccessToast('Blog created successfully');
      }
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateBlog({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      const result = await updateBlogRequest(id, data);
      if (result.code === 200) {
        showSuccessToast('Blog updated successfully');
      }
      return result;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deleteBlog({ commit }, { data, id }) {
    try {
      commit('SET_LOADING', true);
      await deleteBlogRequest(id, data);
      showSuccessToast('Blog deleted successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getBlogTags({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getBlogTagsRequest();
      commit('SET_TAGS', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async createBlogTag({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      await createBlogTagRequest(payload);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deleteBlogTag({ commit }, { data, id }) {
    try {
      commit('SET_LOADING', true);
      await deleteBlogTagRequest(id, data);
      showSuccessToast('Tag deleted successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
}


export const getters = {
  blogList(state) {
    return state.blogs;
  },
  blog(state) {
    return state.blog;
  },
  blogTagList(state) {
    return state.tags;
  },
}
