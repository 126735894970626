import {
    getVacationsRequest,
    changeVacationStatusRequest,
    createVacationRequest,
    updateVacationRequest,
    downloadFileRequest,
    cancelVacationRequest
} from "@/api/vacation";
import {downloadFile} from "@/utils/files";

export const state = {
    vacationData: null,
    tabs: [],
    loading: false
}

export const mutations = {
    SET_LIST(state, { key, value }) {
        state[key] = value
    },
    SET_LOADING(state, value) {
        state.loading = value;
    },
    SET_TABS(state, tabs) {
        state.tabs = tabs;
    }
}
export const actions = {
    async getVacations({ commit }, params) {
        try {
            commit('SET_LOADING', true);
            const { data, code } = await getVacationsRequest(params);
            if (data && code === 200) {
                commit('SET_LIST', { key: 'vacationData', value: data ?? {} })
                commit('SET_TABS', data?.tabs ?? []);
            }
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_LOADING', false);
        }
    },
    async createVacation({ commit }, payload) {
        try {
            commit('SET_LOADING', true);
            const { data, code } = await createVacationRequest(payload);
            if (data && code === 200) {
                this._vm.$showSuccessToast('Vacation Request created successfully')
            }
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_LOADING', false);
        }
    },
    async updateVacation({ commit }, payload) {
        try {
            commit('SET_LOADING', true);
            const { data, code } = await updateVacationRequest(payload);
            if (data && code === 200) {
                this._vm.$showSuccessToast('Vacation Request updated successfully')
            }
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_LOADING', false);
        }
    },
    async changeVacationStatus({ commit }, payload) {
        try {
            commit('SET_LOADING', true);
            const { data, code } = await changeVacationStatusRequest(payload);
            if (data && code === 200) {
                this._vm.$showSuccessToast('Vacation Request status updated successfully')
            }
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_LOADING', false);
        }
    },
    async cancelVacationStatus({ commit }, id) {
        try {
            commit('SET_LOADING', true);
            const { data, code } = await cancelVacationRequest(id);
            if (data && code === 200) {
                this._vm.$showSuccessToast('Vacation Request status canceled successfully')
            }
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_LOADING', false);
        }
    },
    async downloadFile({ commit }, payload) {
        try {
            commit('SET_LOADING', true);
            const response = await downloadFileRequest(payload);
            const blob = new Blob([response], { type: response.type })
            downloadFile(blob, 'vacation-requests.csv');
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_LOADING', false);
        }
    },
}

export const getters = {
    vacationItems(state) {
        return state.vacationData?.items?.data ?? []
    },
    totalVacationItems(state) {
        return state.vacationData?.items?.total ?? 0
    },
    employees(state) {
        return state.vacationData?.employees ?? []
    },
    approvedVacationRequests(state) {
        return state.vacationData?.approvedVacationRequests ?? []
    },
    vacationStatuses(state) {
        return state.vacationData?.statuses?.length ? state.vacationData.statuses.map((el, idx) => {
            let title = el.split('_').join(' ')
            return {
                id: idx + 1,
                key: el,
                title: title.charAt(0).toUpperCase() + title.slice(1)
            }
        }) : []
    }
}
