<script>
import Vertical from "./vertical";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  components: {
    Vertical
  }
};
</script>

<template>
<div>
  <vertical v-load="loading">
    <slot />
  </vertical>
</div>
</template>
