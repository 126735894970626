import {
  sendFeedbackRequest,
  getFeedbacksRequest
} from '@/api/feedback';

export const state = {
  list: [],
  loading: false,
  currentFeedback: null
}

export const mutations = {
  SET_LIST(state, list) {
    state.list = list
  },
  SET_LOADING(state, value) {
    state.loading = value;
  },
  SET_CURRENT_FEEDBACK (state, feedback) {
    state.currentFeedback = feedback
  }
}

export const actions = {
  async getFeedbacks({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getFeedbacksRequest();
      commit('SET_LIST', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async sendFeedback({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      await sendFeedbackRequest(payload);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
}


export const getters = {
  feedbackList(state) {
    return state.list;
  },
}
