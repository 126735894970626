import * as filesRequests from '@/api/files';
import * as AppsWidgetApi from '@/api/apps-widget';

export const state = {
  apps: [],
  groups: [],
  accountServices: [],
  keyclockServices: []
};

export const mutations = {
  SET_APPS(state, list) {
    state.apps = list;
  },
  SET_GROUPS(state, list) {
    state.groups = list;
  },
  SET_ACCOUNT_SERVICES(state, services) {
    state.accountServices = services;
  },
  SET_KEYCLOCK_SERVICES(state, services) {
    state.keyclockServices = services;
  }
};

export const actions = {
  async getApps({ commit }) {
    try {
      const { code, data } = await AppsWidgetApi.getAppsRequest();
      if (code === 200 && data?.length) {
        commit('SET_APPS', data);
      }
    } catch (e) {
      console.error(e);
    }
  },
  async createApp(_, payload) {
    try {
      const { code, data } = await AppsWidgetApi.createAppRequest(payload);
      if (code === 200) {
        this._vm.$showSuccessToast('App created successfully');
        return data;
      }
    } catch (e) {
      console.error(e);
      this._vm.$showErrorToast(e || 'Something went wrong');
    }
  },
  async deleteApp(_, payload) {
    try {
      const { code } = await AppsWidgetApi.deleteAppRequest(payload);
      if (code === 200) {
        this._vm.$showSuccessToast('App deleted successfully');
      }
    } catch (e) {
      console.error(e);
    }
  },
  async updateApp(_, { id, data }) {
    try {
      const { code } = await AppsWidgetApi.updateAppRequest(id, data);
      if (code === 200) {
        this._vm.$showSuccessToast('App updated successfully');
      }
    } catch (e) {
      console.error(e);
      this._vm.$showErrorToast(e || 'Something went wrong');
    }
  },
  async uploadAppIcon(_, payload) {
    try {
      await filesRequests.uploadFilesRequest(payload);
    } catch (e) {
      console.error(e);
    }
  },
  async getServices({ commit }) {
    try {
      const { code, data } = await AppsWidgetApi.getAppsServicesRequest();
      if (code === 200 && data) {
        commit('SET_ACCOUNT_SERVICES', data.accountServices ?? []);
        commit('SET_KEYCLOCK_SERVICES', data.keyclockServices ?? []);
      }
    } catch (e) {
      console.error(e);
    }
  },
  async getGroups({ commit }) {
    try {
      const { code, data } = await AppsWidgetApi.getAppsGroupsRequest();
      if (code === 200 && data?.length) {
        commit('SET_GROUPS', data);
      }
    } catch (e) {
      console.error(e);
    }
  },
  async createGroup(_, payload) {
    try {
      const { code } = await AppsWidgetApi.createAppsGroupRequest(payload);
      if (code === 200) {
        this._vm.$showSuccessToast('Group created successfully');
      }
    } catch (e) {
      console.error(e);
      this._vm.$showErrorToast(e || 'Something went wrong');
    }
  },
  async editGroup(_, { id, data }) {
    try {
      const { code } = await AppsWidgetApi.updateAppsGroupRequest(id, data);
      if (code === 200) {
        this._vm.$showSuccessToast('Group updated successfully');
      }
    } catch (e) {
      console.error(e);
      this._vm.$showErrorToast(e || 'Something went wrong');
    }
  },
  async deleteGroup(_, payload) {
    try {
      const { code } = await AppsWidgetApi.deleteAppsGroupRequest(payload);
      if (code === 200) {
        this._vm.$showSuccessToast('Group deleted successfully');
      }
    } catch (e) {
      console.error(e);
    }
  },
}
