<template>
  <div
      class="support-block"
      @click.prevent="moveToPage('https://bimi-boo.atlassian.net/servicedesk/customer/portals')"
  >
    <a
        href="#"
        target="_blank"
        class="d-flex align-items-center"
    >
      <i class='bx bx-help-circle mr-1 font-size-18'></i>
      Support
    </a>
  </div>
</template>
<script>
export default {
  name: "SupportBlock",
  methods: {
    moveToPage(url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.support-block {
  position: fixed;
  bottom: 20px;
  right: 12px;
  background-color: #556ee6;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 20px;
  transition: .2s ease;
  opacity: .6;
  z-index: 1000;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  a {
    font-size: 14px;
    color: #ffffff;
    font-weight: 500;
  }
}
</style>
