import request from '@/utils/request';
import qs from "qs";

export const getEmployeesRequest = (data) => request.get('/employee-list', { params: data });

export const getEmployeeRequest = (id) => request.get(`/employee-list/${id}`);

export const createEmployeeNoteRequest = (id, data) => request.post(`/employees/notes/${id}`, data);

export const updateEmployeeNoteRequest = (noteId, data) => request.put(`/employees/notes/${noteId}`, data);

export const deleteEmployeeNoteRequest = (noteId) => request.delete(`/employees/notes/${noteId}`);

export const getAllEmployees = () => request.get('/employees');

export const getAllSubordinates = (type, params) => {
    const queryParam = params ? `?${qs.stringify(params, { skipNulls: true })}` : ''

    return request.get(`/employees/list/${type === 1 ? 'subordinates' : 'freelancers'}${queryParam}`);
}

export const getEmployeeNotesRequest = (uid) => request.get(`/employees/notes/${uid}`);
