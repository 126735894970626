export const FORM_MODE = {
  EDIT: 'edit',
  CREATE: 'create',
  SHOW: 'show',
  UPLOAD: 'upload'
};

export const FREELANCER_ID = 6;

export const VACATION_HOURS_TYPE = {
  LEFT: 'left',
  SPENT: 'spent'
};

export const ALL_CATEGORIES_OPTION = { title: 'All Categories', id: null };

export const WARNING_PERIOD = 30;

export const REQUISTION_TYPES = {
  REQUEST: 'request',
  PURCHASE: 'purchase'
};

export const REQUEST_CATEGORIES = {
  LANGUAGE_COURSES: 'Language Courses',
  MEDICAL_EXPENSES: 'Medical Expenses',
  FITNESS_AND_SPORT: 'Fitness & Sport',
  LEARNING_AND_DEVELOPMENT:'L&D Request',
  BIMICOIN_EXPENDITURE: 'Bimicoin Expenditure',
  BOOKS_AND_PUBLICATIONS: 'Books & Publications',
  EQUIPMENT_AND_HARDWARE: 'Equipment & Hardware',
  OFFICE_MAINTENANCE: 'Office Maintenance'
}
export const DEFAULT_EMPLOYEE_SCHEDULE = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday'
]
export const WORK_HOURS = 7

export const REMOTE_LOCATION_ID = 4;
