import request from '@/utils/request';

export const fetchPlatforms = (payload) => request.get('/platforms', {params: payload})

export const showPlatform = (id) => request.get(`/platforms/${id}`)

export const createPlatform = (data) => request.post('/platforms', data)

export const updatePlatform = (data) => request.post(`/platforms/${data.id}`, data.formData)

export const deletePlatform = (data) => request.post(`/platforms/${data.id}`, data.formData)

export const updatePlatformsOrderRequest = (data) => request.post('/platforms/order', data);
