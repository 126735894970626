import {
  getCoinRequests,
  //createCoinRequest,
  updateCoinRequest,
  //deleteCoinRequest,
  getTypesRequest,
  getStatusesRequest,
  addCoinRequest,
  downloadCoinCsvRequest,
} from '@/api/moderator-view';
import {showSuccessToast, showErrorToast} from "@/utils/toasts";
import {downloadFile} from "@/utils/files";

export const state = {
  coinRequestList: [],
  loading: false,
  types: [],
  statuses: [],
  addTypes: [],
}

export const mutations = {
  SET_COIN_REQUEST(state, list) {
    state.coinRequestList = list
  },
  SET_TYPES(state, list) {
    state.types = list
  },
  SET_STATUSES(state, list) {
    state.statuses = list
  },
  SET_LOADING(state, value) {
    state.loading = value;
  },
  SET_ADD_TYPES(state, list) {
    state.addTypes = list;
  }
}

export const actions = {
  async getTypesRequest({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getTypesRequest();
      commit('SET_TYPES', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getBimiCoinAddTypes({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getTypesRequest({ change_balance_type: 1 });
      commit('SET_ADD_TYPES', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getStatusesRequest({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getStatusesRequest();
      commit('SET_STATUSES', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  // ------------------------------------------------------------
  async getCoinRequests({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getCoinRequests(payload);
      commit('SET_COIN_REQUEST', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  /*async createCoinRequest({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await createCoinRequest(payload);
      if (data.code === 200) {
        showSuccessToast('Request created successfully');
        return data;
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },*/
  async updateCoinRequest({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      const result = await updateCoinRequest(id, data);
      if (result.code === 200) {
        showSuccessToast('Request updated successfully');
        return result;
      }
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async addBimiCoinsForUser({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await addCoinRequest(payload);
      if (data.code === 200 || data.code === 201) {
        showSuccessToast(data.data.message);
        return new Promise((resolve) => {
          resolve(true)
        })
      } else {
        showErrorToast('Something went wrong')
        return new Promise((resolve) => {
          resolve(false)
        })
      }
    } catch (e) {
      showErrorToast('Something went wrong')
      return new Promise((resolve) => {
        resolve(false)
      })
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async downloadModeratorViewCSV({commit}, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await downloadCoinCsvRequest(payload);
      const blob = new Blob([data], { type: data.type })
      downloadFile(blob, `moderator-view-${new Date().toISOString()}.csv`);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  /*async deleteCoinRequest({ commit }, { data, id }) {
    try {
      commit('SET_LOADING', true);
      await deleteCoinRequest(id, data);
      showSuccessToast('Request deleted successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },*/
}


export const getters = {
  coinRequestList(state) {
    return state.coinRequestList;
  },
  types(state) {
    return state.types;
  },
  statuses(state) {
    return state.statuses;
  },
}
