import request from '@/utils/request';

export const getDismissalWarningsRequest = (params) => request.get('/dismissal-warnings', { params });

export const getDismissalWarningRequest = (id) => request.get(`/dismissal-warnings/${id}`);

export const createDismissalWarningRequest = (data) => request.post('/dismissal-warnings', data);

export const updateDismissalWarningRequest = (id, data) => request.patch(`/dismissal-warnings/${id}`, data);

export const getDismissalTemplatesRequest = () => request.get('/dismissal-warning-categories');

export const createDismissalTemplateRequest = (data) => request.post('/dismissal-warning-categories', data);

export const updateDismissalTemplateRequest = (id, data) => request.put(`/dismissal-warning-categories/${id}`, data);

export const deleteDismissalTemplateRequest = (id) => request.delete(`/dismissal-warning-categories/${id}`);
