import { getCurrencyRatesRequest } from '@/api/currency-rates';

export const state = {
  currencyRates: [],
  loading: false,
}

export const mutations = {
  LOADING(state, loader) {
    state.loading = loader
  },
  SET_CURRENCY_RATES(state, rates) {
    state.currencyRates = rates;
  }
}

export const actions = {
  changeLoadingValue({ commit }, loading) {
    commit('LOADING', loading)
  },
  async getCurrencyRates({ commit }) {
    try {
      const response = await getCurrencyRatesRequest();
      if (response.code === 200 && response.data) {
        commit('SET_CURRENCY_RATES', response.data);
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export const getters = {
  getCurrencyRate: (state) => (currency) => {
    return state.currencyRates.find((item) => item.currency === currency);
  }
}
