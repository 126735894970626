import Vue from 'vue'
import { prototypes } from "./global";
import BootstrapVue from 'bootstrap-vue'
import VueRouter from 'vue-router'
import vco from "v-click-outside"
import router from './router/index'
import Multiselect from 'vue-multiselect';
import EmptyPage from "@/components/empty-page.vue";
import VueSweetalert2 from 'vue-sweetalert2'
import VueCookies from 'vue-cookies'
import linkify from 'vue-linkify'
import VAnimateCss from 'v-animate-css'
import VuePhoneNumberInput from 'vue-phone-number-input'
import MainLayout from './router/layouts/main'
import Auth from "@/plugins/auth";
import keycloakAuth from "@/utils/keycloakAuth";
import {Vuelidate} from "vuelidate";
import './plugins/directives'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import './plugins/datepicker'
import './plugins/v-scroll'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import VueLazyload from 'vue-lazyload'

import "@/design/index.scss";
import 'swiper/css/swiper.css'

import store from '@/state/store'
import '@/assets/style/main.scss'

//mixins
import GlobalMixin from '@/mixins/global-mixin'
Vue.mixin(GlobalMixin);

import App from './App.vue'

Vue.use(VueRouter)
Vue.use(vco)
Vue.use(global)
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(VueSweetalert2);
Vue.use(VueCookies)
Vue.use(VAnimateCss)
Vue.use(Auth)
Vue.use(VueAwesomeSwiper);
Vue.use(prototypes)

Vue.use(Vuelidate)
Vue.use(VueLazyload)

Vue.component('vue-phone-number-input', VuePhoneNumberInput)
Vue.component('layout', MainLayout)
Vue.component('multiselect', Multiselect);
Vue.component('EmptyPage', EmptyPage)

Vue.directive('linkified', linkify)

keycloakAuth({Vue, store}, () => {
      new Vue({
        el: "#app",
        router,
        store,
        render: (h) => h(App),
      })
    }
)
