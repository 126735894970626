import {
  getProfileRequest,
  updateProfileRequest,
  uploadProfileDocumentRequest,
} from '@/api/offer';

export const state = {
  profile: null,
  loading: false,
}

export const mutations = {
  SET_PROFILE(state, profile) {
    state.profile = profile;
  },
  SET_LOADING(state, value) {
    state.loading = value;
  }
}

export const actions = {
  async getProfile({ commit }, token) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getProfileRequest(token);
      commit('SET_PROFILE', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateProfile({ commit }, {data: profile}) {
    try {
      commit('SET_LOADING', true);
      await updateProfileRequest(profile);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async uploadProfileDocument({ commit }, {type, reqData}) {
    try {
      commit('SET_LOADING', true);
      const {data} = await uploadProfileDocumentRequest(type, reqData);
      commit('SET_PROFILE', data);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  }
};
