import { getDepartmentsRequest } from '@/api/departments';

export const state = {
  list: [],
  loading: false
}

export const getters = {
  getDepartments: state => state.list,
}

export const mutations = {
  SET_LIST(state, list) {
    state.list = list
  },
  SET_LOADING(state, value) {
    state.loading = value;
  }
}

export const actions = {
  async getDepartments({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getDepartmentsRequest();
      commit('SET_LIST', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },   
};

