import request from '@/utils/request';

export const createOrgStructRequest = (data) => request.post('/orgstruct', data);

export const getOrgStructRequest = () => request.get(`orgstruct`);

export const getOrgStructTreeRequest = () => request.get(`/orgstruct-tree`);

export const updateOrgStructRequest = (id, data) => request.post(`orgstruct/${id}`, data);

export const deleteOrgStructRequest = (id) => request.delete(`/orgstruct/${id}`);

export const statePositionsRequest = () => request.get('/state-positions');
