import {
  getTeamRequest,
  getBirthdaysCalendarRequest,
    checkSubordinates
} from '@/api/team';
import GlobalMixin from '@/mixins/global-mixin';

export const state = {
  list: [],
  loading: false,
  birthdays: []
}

export const mutations = {
  SET_LIST(state, list) {
    state.list = list
  },
  SET_LOADING(state, value) {
    state.loading = value;
  },
  SET_BIRTHDAYS(state, value) {
    state.birthdays = value;
  }
}

export const actions = {
  async getTeam({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getTeamRequest();
      commit('SET_LIST', data);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getBirthdaysCalendar({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getBirthdaysCalendarRequest();
      commit('SET_BIRTHDAYS', data);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async checkSubordinateStatus({ commit }) {
    try {
      commit('SET_LOADING', true);
      const data = await checkSubordinates();
      return data
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
}

export const getters = {
  teamByDepartmets(state) {
    const departments = new Set();
    const users = {};
    const positionLists = [];
    state.list.forEach((team) => {
      team?.positions.forEach((position)=>{
        positionLists.push({...team,position})
        departments.add(position?.department?.title);
      })
    });
    const list = Array.from(departments).sort((a, b) => a?.toLowerCase() > b?.toLowerCase() ? 1 : -1);
    list.forEach(dep=>{
      let filterred = positionLists.filter(({position})=>position.department?.title == dep);
      users[dep] = GlobalMixin.methods.groupByKey(filterred,'email');
    })
    return users;
  },
}
