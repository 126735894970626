import {
  savePersonalFileLinkRequest,
  getPersonalFilesLinksRequest,
} from '@/api/personal-file-links';
import { showSuccessToast, showErrorToast } from "@/utils/toasts";

export const state = {
  list: [],
  departments: []
}

export const mutations = {
  SET_LIST(state, {key, value}) {
    state[key] = value;
  }
}

export const actions = {
  async getPersonalFilesLinks({ commit }) {
    commit('SET_LIST', {key: 'list', value: []});
    commit('SET_LIST', {key: 'departments', value: []});
    try {
      const response = await getPersonalFilesLinksRequest();
      if (response.code === 200 && response.data) {
        commit('SET_LIST', {key: 'list', value: response.data});
        commit('SET_LIST', {key: 'departments', value: response.data.map(el => {
          return {
            id: el.department.id,
            title: el.department.title
          }
        })});
      }
    } catch (e) {
      console.error(e);
    }
  },
  async savePersonalFileLink(_, payload) {
    try {
      const response = await savePersonalFileLinkRequest(payload);
      if (response.code === 200) {
        showSuccessToast('Link saved successfully!');
      } else {
        showErrorToast(response?.message ?? 'Something went wrong!');
      }
    } catch (e) {
      console.error(e);
      showErrorToast('Something went wrong!');
    }
  },
}

export const getters = {
  getDepartments(state) {
    return state.departments.length
        ? state.departments.reduce(function (acc, cur) {
          if (!acc.some(item => item?.id === cur.id))
            acc.push(cur);
          return acc;
          }, []).sort((a, b) => a.title.localeCompare(b.title))
        : []
  }
}
