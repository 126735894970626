import request from '@/utils/request';
import qs from "qs";

export const getManagers = () => request.get('/employees/managers')
export const getSubordinates = () => request.get('/employees/subordinates')
export const storeFeedback = (data) => request.post('/regular-feedback', data)
export const getTypes = () => request.get('/regular-feedback/type')
export const getAllFeedbacks = (params) => {
    const queryParam = params ? `?${qs.stringify(params, { skipNulls: true })}` : ''
    return request.get(`/regular-feedback/all${queryParam}`);
}
export const getReceivedFeedbacks = (params) => {
    const queryParam = params ? `?${qs.stringify(params, { skipNulls: true })}` : ''
    return request.get(`/regular-feedback/recipient${queryParam}`);
}
export const getMyFeedbacks = (params) => {
    const queryParam = params ? `?${qs.stringify(params, { skipNulls: true })}` : ''
    return request.get(`/regular-feedback/author${queryParam}`);
}
export const getAllSentFeedback = () => request.get('/regular-feedback/sent');
export const getAllReceivedFeedback = () => request.get('/regular-feedback/received');

export const getAllEmployeesRequest = () => request.get('/regular-feedback/employees');

export const getSentFeedbacksByIdRequest = (id) => request.get(`/regular-feedback/sent/${id}`);

export const getReceivedFeedbacksByIdRequest = (id) => request.get(`/regular-feedback/received/${id}`);
