import { getAccountData, filterAccountsByEmployees } from "../../api/bimi-accounts";
import { showErrorToast } from "../../utils/toasts";

export const state = {
    accounts: [],
    loading: false
}


export const mutations = {
    SET_ACCOUNT_DATA (state, data) {
        state.accounts = data
    },
    SET_LOADING (state, status) {
        state.loading = status
    }
}

export const actions = {
    async getAccountData ({ commit }) {
        commit('SET_LOADING', true)
        try {
            const data = await getAccountData()
            if (data.code === 200 || data.code === 201) {
                commit('SET_ACCOUNT_DATA', data.data)
            } else {
                showErrorToast(`${data.message}`)
            }
        } catch (e) {
            return false
        } finally {
            commit('SET_LOADING', false)
        }
    },
    async filterAccounts ({commit}, options) {
        commit('SET_LOADING', true)
        try {
            const data = await filterAccountsByEmployees(options)
            if (data.code === 200 || data.code === 201) {
                commit('SET_ACCOUNT_DATA', data.data)
            } else {
                showErrorToast(`${data.message}`)
            }
        } catch (e) {
            return false
        } finally {
            commit('SET_LOADING', false)
        }
    }
}
