import axios from 'axios';
import Vue from "vue";
import { showErrorToast } from '@/utils/toasts';

export const API_PATH = process.env.VUE_APP_API_PATH ?  process.env.VUE_APP_API_PATH : 'https://mybimiboo.com/api';

axios.defaults.crossDomain = true;
axios.defaults.withCredentials = true;

export const createService = (baseURL) => {
  const service = axios.create({ baseURL });

  service.interceptors.request.use(
      function (config) {
          const token = Vue.$keycloak.token;
          config.headers["Authorization"] = `Bearer ${token}`;
          return config;
      },
      function (error) {
          return Promise.reject(error);
      }
  );

  service.interceptors.response.use(
    (res) => {
      const { data } = res;
      if (data.code) {
        if (data.code && data.code === 200) {
          return data;
        } else if (data.code === 500) {
            showErrorToast(data.message.message);
            return;
        }
        else {
          const extraMessage = data.data ? '<br><ul>' + data.data.map(e => '<li>' + e + '</li>') + '</ul>' : '';

          showErrorToast(data.message + extraMessage);
          throw data.message;
        }
      } else {
          if(data.message){
              showErrorToast(data.message);
          }
        return data;
      }
    },
    (error) => {
      showErrorToast(error.message);
    }
  );

  return service;
}

const service = createService(API_PATH);

export default service;
