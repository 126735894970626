import {
    getAllEmployees,
    getEmployeesRequest,
    getEmployeeRequest,
    createEmployeeNoteRequest,
    updateEmployeeNoteRequest,
    deleteEmployeeNoteRequest,
    getAllSubordinates,
    getEmployeeNotesRequest
} from '@/api/employees';
import { showSuccessToast } from "@/utils/toasts";

export const state = {
  allEmployees: [],
  employees: null,
  employee: {},
  loading: false,
  filterEmployees: {
    position: null,
    status: null,
    search: ''
  },
  subordinates: [],
  employeeNotes: []
}

export const mutations = {
  SET_ALL_EMPLOYEES(state, value) {
    state.allEmployees = value;
  },
  SET_SUBORDINATES (state, data) {
    state.subordinates = data
  },
  SET_EMPLOYEES(state, list) {
    if (list.current_page === 1) {
      state.employees = list
    } else {
      state.employees?.data.push(...list?.data)
    }
  },
  SET_EMPLOYEE(state, value) {
    if (value) {
      state.employee = {...value, ...value?.contracts.sort((a, b) => a.start_date > b.start_date ? 1 : -1)};
    } else {
      state.employee = value
    }
  },
  SET_LOADING(state, value) {
    state.loading = value;
  },
  SET_FILTER_EMPLOYEES(state, payload) {
    for (const [key, value] of Object.entries(payload)) {
      state.filterEmployees[key] = value
    }
  },
  SET_EMPLOYEE_NOTES(state, notes) {
    state.employeeNotes = notes;
  }
}

export const actions = {
  async getAllEmployees({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getAllEmployees();
      commit('SET_ALL_EMPLOYEES', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getEmployees({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getEmployeesRequest(payload);
      commit('SET_EMPLOYEES', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getEmployeeById({ commit }, id) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getEmployeeRequest(id);
      commit('SET_EMPLOYEE', data);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getEmployeeNotes({ commit }, uid) {
    try {
      commit('SET_LOADING', true);
      const {data} = await getEmployeeNotesRequest(uid);
      commit('SET_EMPLOYEE_NOTES', data);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  clearEmployee({ commit }) {
    commit('SET_EMPLOYEE', null);
  },
  async createEmployeeNote({ commit }, { id, data: note }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await createEmployeeNoteRequest(id, note);
      showSuccessToast('Note created successfully');
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateEmployeeNote({ commit }, { id, data: note }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await updateEmployeeNoteRequest(id, note);
      showSuccessToast('Note updated successfully');
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deleteEmployeeNote({ commit }, id) {
    try {
      commit('SET_LOADING', true);
      await deleteEmployeeNoteRequest(id);
      showSuccessToast('Note deleted successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getSubordinates({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await getAllSubordinates(1, payload);
      if (data) {
        commit('SET_SUBORDINATES', data.data)
        return new Promise((resolve) => {
          resolve(data)
        })
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getFreelancers ({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await getAllSubordinates(2, payload);
      if (data) {
        commit('SET_SUBORDINATES', data.data)
        return new Promise((resolve) => {
          resolve(data)
        })
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
}

export const getters = {
  employeesList(state) {
    return state.employees;
  },
  employee(state) {
    return state.employee;
  },
  allEmployees(state) {
    return state.allEmployees;
  },
  employeeListForSelect (state) {
    return state.allEmployees.filter(el => el.status === 'active').map(item => {
      return {
        uid: item.uid,
        name: item.name,
        department: item.department,
        position: item.position.title,
        photo: item.photo
      }
    })
  },
}
