import request from '@/utils/request';

export const getAppsGroupsRequest = () => request.get('/widget-service-groups');

export const createAppsGroupRequest = (data) => request.post('/widget-service-groups', data);

export const updateAppsGroupRequest = (id, data) => {
  return request.put(`/widget-service-groups/${id}`, data);
}

export const deleteAppsGroupRequest = (id) => request.delete(`/widget-service-groups/${id}`);

export const getAppsRequest = () => request.get('/widget-services');

export const getAppsServicesRequest = () => request.get('/services-list');

export const createAppRequest = (data) => request.post('/widget-services', data);

export const deleteAppRequest = (id) => request.delete(`/widget-services/${id}`);

export const updateAppRequest = (id, data) => request.patch(`/widget-services/${id}`, data);
