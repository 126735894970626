import { showSuccessToast, showErrorToast } from '@/utils/toasts';
import { getUserConfirmation } from '@/utils/dialog';
import {
  fetchCategories,
  showCategory,
  createCategory,
  updateCategory,
  deleteCategory
} from '@/api/categories'

export const state = {
  categories: [],
  category: null
}

export const getters = {
  getCategories: state => state.categories,
  getCategory: state => state.category,
}

export const mutations = {
  SET_CATEGORIES(state, categories) {
    state.categories = categories
  },
  SET_CATEGORY(state, category) {
    state.category = category
  },
}

export const actions = {
  async fetchCategories({ commit }, payload) {
    try {
      const {data} = await fetchCategories(payload)
      commit('SET_CATEGORIES', data)
    } catch (error) {
      showErrorToast(error)
    }
  },
  async showCategory({ commit }, id) {
    try {
      const data = await showCategory(id)
      commit('SET_CATEGORY', data)
    } catch (error) {
      showErrorToast(error)
    }
  },
  async createCategory(_, category) {
    try {
      const result = await createCategory(category)
      if (result) {
        showSuccessToast('Category created successfully')
      }
      return result
    } catch (error) {
      showErrorToast(error)
    }
  },
  async updateCategory(_, category) {
    try {
      const result = await updateCategory(category)
      if (result) {
        showSuccessToast('Category updated successfully')
      }
      return result
    } catch (error) {
      showErrorToast(error)
    }
  },
  async deleteCategory(_, id) {
    try {
      const { isConfirmed } = await getUserConfirmation()

      if (isConfirmed) {
        await deleteCategory(id)
        showSuccessToast('The category has been deleted.')
      }
    } catch (error) {
      showErrorToast(error)
    }
  },
}
