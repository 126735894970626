<template>
  <div style="position: relative" v-click-outside="onClickOutside">
    <div class="sidebar" :class="{'show-sidebar': activeSidebar}">
      <div class="sidebar-logo">
        <a href="#" @click.prevent="$router.push({ name: 'profile' })">
          <img src="@/assets/images/bimi-logo-white.png" alt="">
        </a>
      </div>
      <div class="sidebar-nav">
        <ul>
          <li class="sidebar-nav__item" v-for="item in getMenuItems" :key="item.id">
            <a :href="item.link" v-if="!hasItems(item)" @click.prevent="setActiveMenu(item)" :class="{'active': item.id === activeMenuId}">
              <i :class="'bx ' + item.icon"></i>
              {{ item.label }}
              <i v-if="(item?.alertIcon && checksAndCounts[item.notificationField])" :class="`bx ${item.alertIcon}`" style="margin-right: 0; margin-left: 10px; color:#dd8b8b"></i>
              <b-badge
                pill
                v-if="item.badge && checksAndCounts[item.notificationField]"
                variant="danger"
              >
                {{ checksAndCounts[item.notificationField] }}
              </b-badge>
            </a>
            <div class="w-100" v-if="hasItems(item)">
              <div class="nav-collapse w-100" :class="{'show': (item.id === activeMenuId) || item.subItems.some(e => e.id === activeMenuId)}">
                <div class="nav-collapse__drop" @click.prevent="setActiveMenu(item)">
                  <div class="nav-collapse__drop-content">
                    <a href="#">
                      <i :class="'bx '+ item.icon"></i>{{ item.label }}
                      <i v-if="item?.alertIcon && checksAndCounts[item.notificationField]" :class="`bx ${item.alertIcon}`" style="margin-right: 0; margin-left: 10px; color:#dd8b8b"></i>
                      <b-badge
                        pill
                        v-if="item.badge && checksAndCounts[item.notificationField]"
                        variant="danger"
                      >
                        {{ checksAndCounts[item.notificationField] }}
                      </b-badge>
                    </a>
                    <button v-b-toggle.collapse-1>
                      <i class='bx bx-chevron-up'></i>
                    </button>
                  </div>
                </div>
                <div class="nav-collapse-items">
                  <ul>
                    <li v-for="child in getChild(item.subItems)" :key="child.id">
                      <a :href="child?.link" @click.prevent="setActiveMenu(child)" :class="{'active':child.id === activeMenuId}"> {{ child.label }}
                        <i v-if="child?.alertIcon && checksAndCounts[item.notificationField]" :class="`bx ${item.alertIcon}`" style="margin-right: 0; margin-left: 10px; color:#dd8b8b"></i>
                        <b-badge
                          pill
                          v-if="child.badge && checksAndCounts[child.notificationField]"
                          variant="danger"
                        >
                          {{ checksAndCounts[child.notificationField] }}
                        </b-badge>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="sidebar-bottom">
        <div class="bottom-menu__user" :class="{'bottom-menu__user_active': showLogout}">
          <div v-if="profile?.employee" class="user">
            <div class="avatar-title rounded-circle user-avatar" >
              <i
                v-if="!profile?.employee?.photo"
                class="bx bx-user font-size-24"
                @click="showLogout=!showLogout"
              />
              <img
                v-else
                :src="profile.employee.photo"
                class="cover-parent"
                @click="showLogout=!showLogout"
              />
            </div>
          </div>
          <p class="bottom-menu__user-name" @click="showLogout=!showLogout">
            <span >{{ profile?.employee?.name ?? '' }}</span>
            <span><i class="fas fa-angle-right"></i></span>
            <a href="#" v-show="showLogout" class="bottom-menu__logout" @click.prevent="$keycloak.logout">
              Logout
              <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
            </a>
            <span v-show="showLogout" class="bottom-menu__user-close"></span>
          </p>
        </div>
        <p class="bottom-menu__copy">{{ new Date().getFullYear() }} &copy; Bimiboo</p>
        <div class="bottom-menu__version">Version {{ version }}</div>
      </div>
    </div>
    <div class="sidebar-burger">
      <button @click.prevent="activeSidebar = !activeSidebar">
        <i class="fa fa-fw fa-bars">

        </i>
      </button>
    </div>
  </div>
</template>

<script>
import { menuItems } from "./menu";
import { mapState } from "vuex";

export default {
  name: "CustomSideBar",
  computed:{
    ...mapState('sidebar', {
      activeMenuId: (state) => {
        return state.menu.activeId
      }
    }),
    getMenuItems () {
      const result = this.menuItems.filter(item => this.$hasAccess(item.acl))
      return result.length > 0 ? result : []
    },
    profile() {
      return this.$store.state.profile.profile;
    },
    getSizeForBottomMenu () {
      if (this.sidebarY < 731) {
        return (750 - 550) - 11 + 'px'
      }
      return (this.sidebarY - 550) - 11 + 'px'
    },
    checksAndCounts() {
      return this.$store.state.notifications.checksAndCounts;
    }
  },
  data() {
    return {
      menuItems: menuItems,
      showLogout: false,
      url: process.env.VUE_APP_AUTH_REDIRECT_LINK,
      activeSidebar: false,
      sidebarY: null,
      version: ''
    }
  },
  created() {
    const { buildTimestampUtc } = document.documentElement.dataset;
    if (buildTimestampUtc) {
      this.version = this.$moment(buildTimestampUtc).format('YYMMDD.HHmm');
    }
  },
  async mounted() {
    this.findActiveElementAfterReloadPage (this.$route.path);
    await this.$store.dispatch('products/getCategories').then((res) => {
      if (res) {
        this.dynamicItems()
      }
    })
    this.resizeWindow()
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      return true
    })
  },
  methods: {
    resizeWindow () {
      this.sidebarY = document.getElementsByClassName('sidebar')[0].clientHeight
      window.addEventListener('resize', () => {
        this.sidebarY = document.getElementsByClassName('sidebar')[0].clientHeight
      })
    },
    setActiveMenu(item) {
      if (item.id === this.activeMenuId) {
        this.$store.commit('sidebar/SET_ACTIVE_MENU', null)
        return false
      }
      this.$store.commit('sidebar/SET_ACTIVE_MENU', item.id)
      if (item?.link && item.link !== this.$route.path) {
        this.$router.push(item.link)
      }

    },
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    findActiveElementAfterReloadPage(path){
      let id = null
      this.menuItems.forEach(item => {
        if (item.link === path) {
          id = item.id
        }
        if (item?.subItems) {
          item.subItems.forEach(child => {
            if (child.link === path) {
              id = child.id
            }
          })
        }
      })
      this.$store.commit('sidebar/SET_ACTIVE_MENU', id)
    },
    onClickOutside () {
      this.showLogout = false;
      this.activeSidebar = false;
    },
    dynamicItems(){
      const item = this.menuItems.find(item => item.dynamic)
      let categories;
      if(item.dynamic === 'products'){
        categories = this.$store.getters['products/categoriesList'];
      }

      if(categories){
        item.subItems = categories.map((cat, index) => (
            {
              id: item.id+''+index,
              label: cat.title,
              link: '/products/'+cat.id,
              parentId: item.id
            }
        ))
        item.subItems.push({
          id: item.id+'100',
          label: 'Manage',
          link: '/manage-products',
          parentId: item.id,
          acl: ['admin'],
        })
      }

    },
    enableSubMenuItem (item) {
      if (item.acl) {
        return this.$hasAccess(item.acl);
      }
      return true
    },
    getChild(items) {
      let data = []
      items.forEach(item => {
        if (this.enableSubMenuItem(item)) {
          data.push(item)
        }
      })

      return data
    }
  }
}
</script>

<style lang="scss" scoped>
.user-avatar {
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
}
</style>
