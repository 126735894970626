import request from '@/utils/request';

export const getProfileRequest = () => request.get('/users/profile');

export const uploadProfilePhotoRequest = (data) => request.post('/users/photo', data);

export const updateProfilePhoneRequest = (data) => request.post('/users/phone', data);

export const getCurrentUserProfileRequest = () => request.get('/profile/show');

export const updateProfileRequest = (data) => request.post('/profile/update', data);

export const checkProfileRequest = (data) => request.post('/attendances/check', data);

export const timeRangeProfileRequest = (data) => request.post('/attendances/timerange', data);

export const getCurrentAttendancesRequest = () => request.get('/attendances/current');

export const getBirthdaysRequest = () => request.get('/birthdays/celebrate-today');

export const passwordChange = (data) => request.post('/password-change', data);

export const checkProfileBetaRequest = (data) => request.post('/attendances/check/v2', data);

export const getCheckProfileBetaRequest = () => request.get('/attendances/check/v2');

export const getFinancialInfoRequest = () => request.get('/users/financial');

export const reserveLunchRequest = (data) => request.post('/attendances/reserve-lunch', data);

export const changeEmployeeSettings = (data) => request.post('/employee-settings', data);

export const updateProfileLocationRequest = (data) => request.post('/profile/update-my-location', data);



