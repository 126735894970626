import request from '@/utils/request';

export const getProductsRequest = (id) => request.get('/products/' + id);

export const getCategoriesRequest = () => request.get('/product-categories');

export const fetchProducts = (payload) => request.get('/manage-products', {params: payload})

export const showProduct = (id) => request.get(`/manage-products/${id}`)

export const createProduct = (data) => request.post('/manage-products', data)

export const updateProduct = (data) => request.post(`/manage-products/${data.id}`, data.formData)

export const deleteProduct = (data) => request.post(`/manage-products/${data.id}`, data.formData)