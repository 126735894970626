import request from '@/utils/request';

export const getBlogsRequest = (data) => request.get('/blog-posts', { params: data });

export const getBlogRequest = (id) => request.get(`/blog-posts/${id}`);

export const createBlogRequest = (data) => request.post('/blog-posts', data);

export const updateBlogRequest = (id, data) => request.post(`/blog-posts/${id}`, data);

export const deleteBlogRequest = (id, data) => request.post(`/blog-posts/${id}`, data);

export const getBlogTagsRequest = (data) => request.get('/blog-tags', data);

export const createBlogTagRequest = (data) => request.post('/blog-tags/', data);

export const deleteBlogTagRequest = (id) => request.post(`/blog-tags/${id}`);
