<script>
import appConfig from "@/app.config";

import moment from 'moment';
import Swal from "sweetalert2";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  async mounted() {
    await this.$store.dispatch('profile/getProfile');
    this.$store.dispatch('notifications/getNotificationChecksAndCounts');
    if (this.currentRoute !== '/fill-profile-fully' && this.currentRoute !== '/fill-profile-shortly'){
      await this.showFeedbackAlert()
    }
  },
  methods: {
    showBetaFeedbackAlert () {
      let today = this.$moment()
      if (today.format('dddd') === 'Thursday' && this.calendar.length > 0 && !this.feedbackStatus) {
        let tomorrowDay = this.$moment(today).add(1, 'days').format('YYYY-MM-DD')
        let tomorrowType = this.calendar.find(item => item.date === tomorrowDay)?.type
        let todayType = this.calendar.find(item => item.date === today.format('YYYY-MM-DD'))?.type
        if (tomorrowType === 'holiday' && todayType !== 'holiday') {
          Swal.fire({
            title: "Please send regular feedback!",
            text: "Want to send it?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Yes!"
          }).then(result => {
            if (result.value) {
              this.$router.push(`/weekly-feedback/add`);
            }
          });
        }
      }
      if (today.format('dddd') === process.env.VUE_APP_FEEDBACK_DATE && this.calendar.length > 0 && !this.feedbackStatus) {
        let findDate = this.calendar.find(item => item.date === today.format('YYYY-MM-DD'))
        if (findDate.type !== 'holiday') {
          Swal.fire({
            title: "Please send regular feedback!",
            text: "Want to send it?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Yes!"
          }).then(result => {
            if (result.value) {
              this.$router.push(`/weekly-feedback/add`);
            }
          });
        }
      }
    },
    async showFeedbackAlert() {
      var key = 'feedbackLastSeenDate';
      var feedbackLastSeenDate = localStorage.getItem(key);
      if (!feedbackLastSeenDate || moment(feedbackLastSeenDate, 'YYYY-MM-DD hh:mm').add(1, 'month').isBefore()) {
        Swal.fire({
          title: "Please send regular feedback!",
          text: "Want to send it?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes!"
        }).then(result => {
          if (result.value) {
            this.$router.push(`/feedbacks`).catch(e => e);
          }
        });
        localStorage.setItem(key, moment().format('YYYY-MM-DD hh:mm'))
      }
    }
  },
  computed: {
    calendar () {
      return this.$store.state.profile.profile?.attendances?.calendar ? this.$store.state.profile.profile.attendances.calendar : []
    },
      currentRoute() {
          return window.location.pathname;
      },
    feedbackStatus () {
        return this.$store.state.weeklyFeedback.feedbackStatus
    }
  }
};
</script>

<template>
  <div id="app">
    <RouterView />
  </div>
</template>
