import request from '@/utils/request';

export const fetchCategories = (payload) => request.get('/product-categories', {params: payload})

export const showCategory = (id) => request.get(`/product-categories/${id}`)

export const createCategory = (data) => request.post('/product-categories', data)

export const updateCategory = (data) => request.post(`/product-categories/${data.id}`, data.formData)

export const deleteCategory = (data) => request.post(`/product-categories/${data.id}`, data.formData)
