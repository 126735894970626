export const menuItems = [
    {
        id: 9,
        label: 'Profile',
        link: "/profile",
        icon: "bxs-user-circle",
        acl: ['all']
    },
    {
        id: 10,
        label: 'Team',
        icon: "bxs-group",
        acl: ['all'],
        subItems: [
            {
                id: 101,
                label: 'Team',
                link: '/team',
                parentId: 10
            },
            {
                id: 102,
                label: 'Hours of availability',
                link: '/team/hours-of-availability',
                parentId: 10
            },
        ]
    },
    {
        id: 11,
        label: 'Library',
        link: "/library",
        icon: 'bxs-book',
        acl: ['all']
    },
    {
        id: 12,
        label: 'Requisitions Hub',
        icon: "bx bxs-shopping-bag-alt",
        acl: ['all'],
        badge: true,
        notificationField: 'totalRequisitionsHub',
        subItems: [
            {
                id: 121,
                label: 'Requisitions',
                link: '/requisitions',
                badge: true,
                notificationField: 'totalRequisitions',
                parentId: 12
            },
            {
                id: 122,
                label: 'Vacation Requests',
                link: '/vacation-requests',
                badge: true,
                notificationField: 'totalVacations',
                parentId: 12
            },
        ]
    },
    {
        id: 14,
        label: 'Time Sheet',
        link: "/time-sheet",
        icon: "bxs-calendar-plus",
        acl: ['hrm', 'admin', 'coo', 'accountant']
    },
    /*{
        id: 30,
        label: 'Work Shedules',
        link: "/work-shedules",
        icon: "bxs-calendar-plus",
        acl: ['hrm', 'office_manager', 'admin', 'coo']
    },*/
    {
        id: 15,
        label: 'Feedback',
        link: "/feedbacks",
        icon: "bxs-conversation",
        acl: ['all']
    },
    {
        id: 16,
        label: 'Regular feedback',
        icon: "bxs-conversation",
        alertIcon: 'bxs-info-circle',
        notificationField: 'is_sent',
        acl: ['all'],
        subItems: [
            {
                id: 161,
                label: 'Dashboard',
                link: '/weekly-feedback/dashboard',
                alertIcon: 'bxs-info-circle',
                notificationField: 'is_sent',
                parentId: 16
            },
            {
                id: 162,
                label: 'General Dashboard',
                link: '/weekly-feedback/general-dashboard',
                parentId: 16,
                acl: ['admin', 'hrm']
            },
            {
                id: 163,
                label: 'Send feedback',
                link: '/weekly-feedback/add',
                parentId: 16
            },
            {
                id: 164,
                label: 'Sent feedback',
                link: '/weekly-feedback/sent',
                parentId: 16
            },
            {
                id: 165,
                label: 'Received feedback',
                link: '/weekly-feedback/received',
                parentId: 16
            },
            {
                id: 166,
                label: 'All feedbacks',
                link: '/weekly-feedback/all',
                parentId: 16,
                acl: ['hrm', 'admin', 'coo', 'manager'],
            },
        ]
    },
    {
        id: 17,
        label: 'Dismissal Warnings',
        link: "/dismissal-warnings",
        icon: "bx bx-error",
        acl: ['admin', 'hrm']
    },
    {
        id: 18,
        label: 'Blog',
        link: "/blogs",
        icon: "bx bx-detail",
        acl: ['all']
    },
    {
        id: 19,
        label: 'Products',
        icon: "bx bxs-package",
        acl: ['all'],
        dynamic: 'products',
        subItems: [
            // {
            //     id: '',
            //     label: '',
            //     link: '',
            //     parentId: 26
            // },
        ]
    },
    {
        id: 20,
        label: 'BimiCoin',
        icon: "bxs-user-check",
        acl: ['all'],
        subItems: [
            {
                id: 2028,
                label: 'Balance Details',
                link: '/bimi-coin/balance-details',
                parentId: 20
            },
            {
                id: 209,
                label: 'Leaderboard',
                link: '/bimi-coin/leaderboard',
                parentId: 20
            },
            {
                id: 2030,
                label: 'Bimi Coin moderator',
                link: '/bimi-coin/moderator-view',
                parentId: 20,
                acl: ['hrm', 'admin', 'accountant'],
            },
            {
                id: 2031,
                label: 'Bimi Coin accounts',
                link: '/bimi-coin/accounts',
                parentId: 20,
                acl: ['hrm', 'admin', 'coo', 'accountant'],
            },
        ]
    },
    // {
    //     id: 26,
    //     label: 'Organisation Structure',
    //     link: "/organisation-structure",
    //     icon: "bx bxs-user",
    //     acl: ['all']
    // },
    {
        id: 21,
        label: 'Company',
        icon: "bx bx-copyright",
        acl: ['all'],
        subItems: [
            {
                id: 211,
                label: 'Ideology',
                link: '/about/mission',
                parentId: 22
            },
            {
                id: 212,
                label: 'Bimi Coins',
                link: '/about/bimi-coins',
                parentId: 22
            },
            {
                id: 213,
                label: 'Structure',
                link: '/organisation-structure',
                parentId: 22
            },
            {
                id: 214,
                label: 'Regulations',
                link: '/about/regulations',
                parentId: 22
            },
            {
                id: 215,
                label: 'General Duties',
                link: '/about/general-duties',
                parentId: 22
            },
            {
                id: 216,
                label: 'Capabilities',
                link: '/about/capabilities',
                parentId: 22
            },
        ]
    },
    {
        id: 22,
        label: 'Employees\' Personal Files',
        link: "/employees-personal-files",
        icon: "bxs-user-detail",
        acl: ['admin', 'hrm', 'hr_generalist']
    },
    {
        id: 23,
        label: 'IAM',
        link: "/permissions",
        icon: "bx bxs-user",
        acl: ['admin']
    }
];
