export default{
    methods:{
        groupByKey(array, key) {
            return array
              .reduce((hash, obj) => {
                if(obj[key] === undefined) return hash;
                return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
              }, {})
        },
        handleBlurNumber(event, name) {
            const modelNames = name.split('.')
            const min = event.target?.min ?? 0
            if (event.target.value < min) {
                this.$set(this[modelNames[0]], modelNames[modelNames.length - 1], 0)
            }
        }
    }
}
