import qs from 'qs';
import request from '@/utils/request';

export const getAttendancesRequest = (params) => {
  const queryParam = params ? `?${qs.stringify(params, { skipNulls: true })}` : ''

  return request.get(`/attendances${queryParam}`);
}

export const getWorkShedulesRequest = (params) => {
  const queryParam = params ? `?${qs.stringify(params, { skipNulls: true })}` : ''

  return request.get(`/team/hours-of-availablity${queryParam}`);
}

export const setDayTypeRequest = (data) => request.post('/calendar', data);

export const updateDayRequest = (data) => request.post('/attendances', data);


export const downloadReportRequest = (year) => request.get(`/attendances/download?year=${year}`, {
  responseType: 'blob'
});

export const removeAttendanceRequest = (data) => request.post(`/attendances/remove`, data);

// new version api attendance

export const createAttendanceRequest = (data) => request.post('/attendances/v2', data);

export const updateAttendance = ({id, data}) => request.post(`/attendances/v2/${id}`, data);

export const deleteAttendance = ({id, data}) => request.post(`/attendances/v2/${id}`, data);

export const getAttendances = (params) => {
  const queryParam = params ? `?${qs.stringify(params, { skipNulls: true })}` : ''

  return request.get(`/attendances/v2${queryParam}`);
}

export const getTypesOfDayRequest = () => request.get('/attendances/type/v2')

export const getLocations = () => request.get('/location')

export const getMyAttendances = (date) => request.get(`/attendances/calendar?date=${date}`);

export const editPeriodRequest = (data) => request.post(`/attendances/period/v2`, data);
