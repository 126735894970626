import { showSuccessToast, showErrorToast } from '@/utils/toasts';
import { getUserConfirmation } from '@/utils/dialog';
import {
  getProductsRequest,
  getCategoriesRequest,
  fetchProducts,
  showProduct,
  createProduct,
  updateProduct,
  deleteProduct
} from '@/api/products';

export const state = {
  products: [],
  product: null,
  categories: [],
  loading: false
}

export const mutations = {
  SET_PRODUCTS(state, value) {
    state.products = value;
  },
  SET_CATEGORIES(state, value) {
    state.categories = value;
  },
  SET_PLATFORMS(state, products) {
    state.products = products
  },
  SET_PLATFORM(state, product) {
    state.product = product
  },
  SET_LOADING(state, value) {
    state.loading = value;
  },
}

export const actions = {
  async getProducts({ commit }, id) {
    try {
      commit('SET_PRODUCTS', []);
      commit('SET_LOADING', true);
      const { data } = await getProductsRequest(id);
      commit('SET_PRODUCTS', data);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getCategories({ commit }, id) {
    try {
      commit('SET_CATEGORIES', []);
      commit('SET_LOADING', true);
      const { data } = await getCategoriesRequest(id);
      commit('SET_CATEGORIES', data);
      return new Promise((resolve) => {
        resolve(true)
      })
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async fetchProducts({ commit }, payload) {
    try {
      const {data} = await fetchProducts(payload)
      commit('SET_PLATFORMS', data)
    } catch (error) {
      showErrorToast(error)
    }
  },
  async showProduct({ commit }, id) {
    try {
      const data = await showProduct(id)
      commit('SET_PLATFORM', data)
    } catch (error) {
      showErrorToast(error)
    }
  },
  async createProduct(_, product) {
    try {
      const result = await createProduct(product)
      if (result) {
        showSuccessToast('Product created successfully')
      }
      return result
    } catch (error) {
      showErrorToast(error)
    }
  },
  async updateProduct(_, product) {
    try {
      const result = await updateProduct(product)
      if (result) {
        showSuccessToast('Product updated successfully')
      }
      return result
    } catch (error) {
      showErrorToast(error)
    }
  },
  async deleteProduct(_, id) {
    try {
      const { isConfirmed } = await getUserConfirmation()

      if (isConfirmed) {
        await deleteProduct(id)
        showSuccessToast('The product has been deleted.')
      }
    } catch (error) {
      showErrorToast(error)
    }
  },
}

export const getters = {
  productsList(state) {
    return state.products;
  },
  getProduct(state) {
    return state.product;
  },
  categoriesList(state) {
    return state.categories;
  }
}