export default [
  {
    path: '/404',
    name: '404',
    component: () => import('./views/utility/404')
  },
  {
    path: '*',
    redirect: '/pages/404',
  },
  {
    path: '/',
    redirect: '/profile',
  },
  {
    path: '/team',
    name: 'team',
    meta: { title: 'Team', roles: ['all'] },
    component: () => import('./views/team/index')
  },
  {
    path: '/birthdays/calendar',
    name: 'birthdays-calendar',
    meta: { title: 'Birthdays calendar', roles: ['all'] },
    component: () => import('./views/team/birthdays/index')
  },
  {
    path: '/library',
    name: 'library',
    meta: { title: 'Library', roles: ['all'] },
    component: () => import('./views/library/index')
  },
  {
    path: '/library/:id(\\d+)',
    name: 'book',
    meta: { title: 'Book', roles: ['all'] },
    component: () => import('./views/book/index')
  },
  {
    path: '/library/settings',
    name: 'librarySettings',
    meta: { title: 'Library Settings', roles: ['admin', 'office_manager'] },
    component: () => import('./views/library-settings/index')
  },
  {
    path: '/library/return-books',
    name: 'returnBooks',
    meta: { title: 'Return Books', roles: ['all'] },
    component: () => import('./views/library-return-books/index')
  },
  {
    path: '/time-sheet',
    name: 'time-sheet',
    meta: { title: 'Time Sheet', roles: ['hrm', 'admin', 'coo', 'accountant'] },
    component: () => import('./views/time-sheet/index')
  },
  {
    path: '/team/hours-of-availability',
    name: 'timelines',
    meta: { title: 'Employees availability', roles: ['all'] },
    component: () => import('./views/team/hours-availability/index.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: { title: 'Profile', roles: ['all'] },
    component: () => import('./views/profile/index')
  },
  {
    path: '/feedbacks',
    name: 'feedbacks',
    meta: { title: 'Feedbacks', roles: ['all'] },
    component: () => import('./views/feedback/list/index')
  },
  {
    path: '/weekly-feedback/dashboard',
    name: 'weekly-feedback-dashboard',
    meta: { title: 'Dashboard', roles: ['all'] },
    component: () => import('./views/weekly-feedback/dashboard')
  },
  {
    path: '/weekly-feedback/general-dashboard',
    name: 'weekly-feedback-general-dashboard',
    meta: { title: 'General Dashboard', roles: ['admin', 'hrm'] },
    component: () => import('./views/weekly-feedback/general-dashboard')
  },
  {
    path: '/weekly-feedback/:id/dashboard',
    name: 'weekly-feedback-user-dashboard',
    meta: { title: 'General Dashboard', roles: ['admin', 'hrm'] },
    component: () => import('./views/weekly-feedback/user-dashboard')
  },
  {
    path: '/weekly-feedback/add',
    name: 'weekly-feedback-add',
    meta: { title: 'Feedbacks add', roles: ['all'] },
    component: () => import('./views/weekly-feedback/add')
  },
  {
    path: '/weekly-feedback/received',
    name: 'weekly-feedback-received',
    meta: { title: 'Received feedbacks', roles: ['all'] },
    component: () => import('./views/weekly-feedback/received')
  },
  {
    path: '/weekly-feedback/sent',
    name: 'weekly-feedback-sent',
    meta: { title: 'Sent feedbacks', roles: ['all'] },
    component: () => import('./views/weekly-feedback/sent')
  },
  {
    path: '/weekly-feedback/all',
    name: 'weekly-feedback-all',
    meta: { title: 'Feedbacks All', roles: ['admin', 'hrm', 'coo', 'manager'] },
    component: () => import('./views/weekly-feedback/all')
  },
  {
    path: '/dismissal-warnings',
    name: 'dismissal-warnings',
    meta: { title: 'Dismissal Warnings', roles: ['all'] },
    component: () => import('./views/dismissal-warning/index')
  },
  {
    path: '/bimi-coin/leaderboard',
    name: 'leaderboard',
    meta: { title: 'Leaderboard', roles: ['all'] },
    component: () => import('./views/bimi-coin/leaderboard/index')
  },
  {
    path: '/bimi-coin/codes-management',
    name: 'codes-management',
    meta: { title: 'Codes management', roles: ['hrm', 'admin'] },
    component: () => import('./views/bimi-coin/codes-management/index')
  },
  {
    path: '/bimi-coin/moderator-view',
    name: 'moderator-view',
    meta: { title: 'Moderator log', roles: ['hrm', 'admin', 'accountant'] },
    component: () => import('./views/bimi-coin/moderator-view/index')
  },
  {
    path: '/bimi-coin/accounts',
    name: 'bimi-accounts',
    meta: { title: 'Accounts', roles: ['hrm', 'admin', 'coo', 'accountant'] },
    component: () => import('./views/bimi-coin/accounts/index')
  },
  {
    path: '/bimi-coin/balance-details',
    name: 'balance-details',
    meta: { title: 'Balance details', roles: ['all'] },
    component: () => import('./views/bimi-coin/balance-details/index')
  },
  {
    path: '/blogs',
    name: 'blogs',
    meta: { title: 'Blogs', roles: ['all'] },
    component: () => import('./views/blogs/index')
  },
  {
    path: '/blogs/:id',
    name: 'blog',
    meta: { title: 'Blog', roles: ['all'] },
    component: () => import('./views/blogs/id')
  },
  {
    path: '/feedback',
    name: 'feedback',
    meta: { title: 'Feedback', roles: ['all'] },
    component: () => import('./views/feedback/index')
  },
  {
    path: '/requisitions',
    name: 'requisitions',
    meta: { title: 'Requisitions', roles: ['all'] },
    component: () => import('./views/purchases/index')
  },
  {
    path: '/requisitions/categories',
    name: 'requisitions-categories',
    meta: { title: 'Requisitions Categories', roles: ['admin'] },
    component: () => import('./views/purchases/categories')
  },
  {
    path: '/requisitions/faq',
    name: 'requisitions-faq',
    meta: { title: 'Requisitions FAQ', roles: ['all'] },
    component: () => import('./views/purchases/faq')
  },
  {
    path: '/vacation-requests',
    name: 'vacation-requests',
    meta: { title: 'Vacation Requests', roles: ['all'] },
    component: () => import('./views/vacation-requests/index.vue')
  },
  {
    path: '/subordinates',
    name: 'subordinates',
    meta: { title: 'Subordinates', roles: ['all'] },
    component: () => import('./views/subordinates/index')
  },
  {
    path: '/subordinates/:id',
    name: 'subordinate',
    meta: { title: 'Subordinate', roles: ['all'] },
    component: () => import('./views/subordinate/index')
  },
  {
    path: '/schedule/:id',
    name: 'schedule',
    meta: { title: 'Individual schedule', roles: ['hrm', 'office_manager', 'admin', 'coo'] },
    component: () => import('./views/schedule/index')
  },
  {
    path: '/organisation-structure',
    name: 'organisation-structure',
    meta: { title: 'Organisation Structure', roles: ['all'] },
    component: () => import('./views/organisation-structure/index')
  },
  {
    path: '/about/:key',
    name: 'about',
    meta: { title: 'About', roles: ['all'] },
    component: () => import('./views/about/index'),
  },
  {
    path: '/products/:id',
    name: 'products',
    meta: { title: 'Products', roles: ['all'] },
    component: () => import('./views/products/index')
  },
  {
    path: '/manage-products',
    name: 'manage-products',
    meta: { authRequired: true, title: 'Products', roles: ['all'] },
    component: () => import('./views/products/manage'),
  },
  {
    path: '/platforms',
    name: 'platforms',
    meta: { authRequired: true, title: 'Products', roles: ['admin'] },
    component: () => import('./views/products/platforms'),
  },
  {
    path: '/product-categories',
    name: 'product-categories',
    meta: { authRequired: true, title: 'Products', roles: ['admin'] },
    component: () => import('./views/products/categories'),
  },
  {
    path: '/pages/starter',
    name: 'Starter',
    meta: { roles: ['all'] },
    component: () => import('./views/utility/starter')
  },
  {
    path: '/pages/404',
    name: 'Error-404',
    meta: { authRequired: true },
    component: () => import('./views/utility/404')
  },
  {
    path: '/pages/500',
    name: 'Error-500',
    meta: { authRequired: true },
    component: () => import('./views/utility/500')
  },
  {
    path: '/permissions',
    name: 'Permissions',
    meta: { roles: ['admin'] },
    component: () => import('./views/permissions/index')
  },
  {
    path: '/fill-profile-fully',
    name: 'FillProfileFully',
    meta: { roles: ['all'] },
    component: () => import('./views/offer/profile/index')
  },
  {
    path: '/fill-profile-shortly',
    name: 'FillProfileShortly',
    meta: { roles: ['all'] },
    component: () => import('./views/offer/fillProfileShortly')
  },
  {
    path: '/employees-personal-files',
    name: 'personalFileLinks',
    meta: { roles: ['admin', 'hrm', 'hr_generalist'], title: 'Employees\' Personal Files' },
    component: () => import('./views/personal-file-links/index')
  },
  {
    path: '/widget-settings',
    name: 'widget-settings',
    meta: { roles: ['admin'], title: 'Widget Settings' },
    component: () => import('./views/settings-apps/index')
  },
  {
    path: '/console-commands',
    name: 'ConsoleCommands',
    meta: { title: 'Console Commands', roles: ['admin'] },
    component: () => import('./views/settings/commands')
  },
]
