import { getMeRequest } from '@/api/user';

export const state = {
  user: {
    name: '',
    roles: [],
    photo: '',
    employee: null,
    uid: null
  },
  loading: false,
}

export const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_LOADING(state, value) {
    state.loading = value;
  }
}

export const actions = {
  async getMe({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getMeRequest();
      if (data && data.user) {
        commit('SET_USER', {
          name: data.user.name,
          roles: data.user.roles,
          photo: data.user.employee ? data.user.employee.photo : '',
          employee: data.user.employee,
          uid: data.user?.employee?.uid,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
