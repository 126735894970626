import {
  getProfileRequest,
  getCurrentUserProfileRequest,
  updateProfileRequest,
  checkProfileRequest,
  checkProfileBetaRequest,
  reserveLunchRequest,
  getCheckProfileBetaRequest,
  timeRangeProfileRequest,
  uploadProfilePhotoRequest,
  updateProfilePhoneRequest,
  getCurrentAttendancesRequest,
  getBirthdaysRequest,
  passwordChange,
  getFinancialInfoRequest,
  changeEmployeeSettings,
  updateProfileLocationRequest
} from '@/api/profile';
import {
  showSuccessToast,
  showErrorToast
} from "@/utils/toasts";

export const state = {
  profile: {},
  profileEdit: {
    loading: false
  },
  financialInfo: null,
  loading: false,
  current: null,
  birthdays: null,
  luckyCoins: 0,
  location: null,
  filterParams: null
}

export const mutations = {
  SET_PROFILE(state, profile) {
    state.profile = profile;
  },
  SET_PROFILEEDIT(state, profile) {
    state.profileEdit = profile;
  },
  SET_LOADING(state, value) {
    state.loading = value;
  },
  SET_PROFILE_PHONE(state, value) {
    if (state.profile) {
      state.profile.employee.phone = value;
    }
  },
  SET_CURRENT(state, value) {
    state.current = value;
  },
  SET_BIRTHDAYS(state, value) {
    state.birthdays = value;
  },
  SET_LUCKY_COINS(state, value) {
    state.luckyCoins = value;
  },
  SET_LOCATION (state, data) {
    state.location = data
  },
  SET_FILTER_PARAMS (state, params) {
    state.filterParams = params
  },
  SET_LIST(state, {key, value}) {
    if (key && value) {
      state[key] = value
    }
  }
}

export const actions = {
  async getProfile({ commit }) {
    try {
      commit('SET_LOADING', true);
      const data = await getProfileRequest();
      if (data.code === 200) {
        commit('SET_PROFILE', data.data);
        return new Promise((resolve) => {
          resolve(true)
        })
      }
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getCurrentUserProfile({ commit }) {
    try {
      commit('SET_LOADING', true);
      const response = await getCurrentUserProfileRequest();
      if (response.code === 200 && response.data) {
        const { profile = {}, photo = null } = response.data;
        commit('SET_PROFILEEDIT', { ...profile, photo });
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateProfile({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const response = await updateProfileRequest(payload);
      if(response.data != null){
        showSuccessToast('Profile changed successfully');
        return response;
      }else{
        showErrorToast('Uid do not match');
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async uploadProfilePhoto({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const res = await uploadProfilePhotoRequest(payload);
      if (res.code === 200) {
        showSuccessToast('Photo upload successfully');
      }
      return res;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateProfilePhone({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      await updateProfilePhoneRequest(payload);
      commit('SET_PROFILE_PHONE', payload.get('phone'));
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getCurrentAttendances({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getCurrentAttendancesRequest();
      commit('SET_CURRENT', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getBirthdays({ commit }) {
    try {
      commit('SET_LOADING', false);
      const { data } = await getBirthdaysRequest();
      commit('SET_BIRTHDAYS', data);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async checkProfile({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await checkProfileRequest(payload);
      commit('SET_LUCKY_COINS', data.luckyAmount);
      return true;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async checkBetaProfile({ commit, dispatch }, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await checkProfileBetaRequest(payload);
      if (data.code === 200 && data.data?.attendance) {
        commit('SET_LUCKY_COINS', data.data?.coin.luckyAmount);
        dispatch('balanceDetails/getBalanceRequest', this.state.user?.user?.uid, {root: true})
        return new Promise((resolve) => {
          resolve(data.data.attendance)
        })
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
    async reserveLunch({ commit }, payload) {
        try {
            commit('SET_LOADING', true);
            const res = await reserveLunchRequest(payload);
            if (res.code === 200) {
                showSuccessToast('Lunch reserved successfully');
            }
            return true;
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_LOADING', false);
        }
    },
  async getCheckBetaProfile({ commit }) {
    try {
      commit('SET_LOADING', true);
      const data = await getCheckProfileBetaRequest();
      if (data.code === 200) {
        commit('SET_LOCATION', data.data.attendance?.location)
        commit('SET_LUCKY_COINS', data.data.attendance?.today_check_in_lucky_coin);
        return new Promise((resolve) => {
          resolve(data.data)
        })
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async timeRangeProfile({ commit }, data ) {
    try {
      commit('SET_LOADING', true);
      //const response = await timeRangeProfileRequest(data);
      //return response;
      const response = await timeRangeProfileRequest(data);
      commit('SET_LUCKY_COINS', response.data.luckyAmount);
      return true;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async passwordChange({ commit }, data) {
    try {
      commit('SET_LOADING', true);
      const res = await passwordChange(data);
      if (res.code === 200) {
        showSuccessToast('Password change confirmed');
      }
      return true;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getFinancialInfo({ commit }) {
    try {
      const res = await getFinancialInfoRequest();
      if (res.code === 200) {
        commit('SET_LIST', { key: 'financialInfo', value: res.data })
      }
    } catch (e) {
      console.error(e);
    }
  },
  async changeEmployeeSettings({ commit }, data) {
    try {
      commit('SET_LOADING', true);
      const res = await changeEmployeeSettings(data);
      if (res.code === 200) {
        showSuccessToast('Employee Settings change successfully');
      }
      return true;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateProfileLocation({ commit }, data) {
    try {
      commit('SET_LOADING', true);
      const response = await updateProfileLocationRequest(data);
      if (response.code === 200) {
        showSuccessToast('Location change successfully');
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

export const getters = {
  profileEdit(state) {
    return state.profileEdit;
  },
  availableVacationHour(state) {
    return state.profile?.attendances?.available_vacation_hours > 0
        ? state.profile.attendances.available_vacation_hours
        : 0
  },
  getCurrentHolidays(state) {
    return state.profile?.holidays ?? []
  },
  getEmployeeSchedule(state) {
    return state.profile?.employee_schedule ?? []
  }
};
