import request from '@/utils/request';

export const getVacationsRequest = (params) => request.get('/vacation-requests', { params })
export const createVacationRequest = (data) => request.post('/vacation-requests', { ...data })
export const updateVacationRequest = ({id, data}) => request.put(`/vacation-requests/${id}`, { ...data })
export const changeVacationStatusRequest = (data) => request.post('/vacation-request-status', { ...data })
export const cancelVacationRequest = (id) => request.delete(`/vacation-requests/${id}`)
export const downloadFileRequest = (params) => request.get('/vacation-requests', {
    params: {
        ...params,
        csv: 1,
    },
    responseType: 'blob'
})
